import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils"
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const customerApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getCustomerById: build.query({
            query: (customerId) => {
                return {
                    url: `/customers/${customerId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["customerById"]
        }),
        getAllRegularCustomers: build.query({
            query: () => {
                return {
                    url: `/customers/regular`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        searchCustomers: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/customers/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['customers'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createCustomer: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/customers`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['customers'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Customer");
            },
        }),

        updateCustomer: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/customers/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['customers', 'customerById', 'userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Customer")
            },
        }),

        getParentCustomerByTenant: build.query({
            query: (tenantId) => {
                return {
                    url: `/customers/parent-customer-by-tenant/${tenantId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            }
        }),


        getAllSubordinateCustomers: build.query({
            query: (id) => {
                return {
                    url: `/customers/subordinate`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                    params: {id}
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            }
        }),

        exportCustomerData: build.query({
            query: (query) => {
                return {
                    url: `/customers/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("customers"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    }),
});


export const {
    useGetAllRegularCustomersQuery,
    useGetCustomerByIdQuery,
    useLazyGetCustomerByIdQuery,
    useSearchCustomersQuery,
    useLazySearchCustomersQuery,
    useCreateCustomerMutation,
    useUpdateCustomerMutation,
    useGetParentCustomerByTenantQuery,
    useGetAllSubordinateCustomersQuery,
    useLazyExportCustomerDataQuery
} = customerApi;