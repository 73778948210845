import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {openFileInNewTab} from "../../../helpers/utils/FileUtils";

export const pdfSettingsApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        updatePdfSettings: build.mutation({
            query: ({customerId, pdfLogo, pdfSettingObj}) => {
                const formData = new FormData();
                formData.append('customerId', customerId);
                formData.append('pdfLogo', pdfLogo);
                formData.append('pdfSettingObj', JSON.stringify(pdfSettingObj));

                return {
                    url: `/pdf-settings`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: formData
                };
            },
            invalidatesTags: ['pdfSettingsByCustomerId'],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Pdf Settings");
            }
        }),

        getPdfSettingsByCustomerId: build.query({
            query: (customerId) => {
                return {
                    url: `/pdf-settings/by-customer-id/${customerId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            }
        }),

        getPdfImageByName: build.query({
            query: (imageName) => {
                return {
                    url: `/pdf-settings/image-by-name/${imageName}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            }
        }),

        viewPdfImage: build.query({
            query: (fileId) => {
                return {
                    url: `/pdf-settings/read-image/${fileId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        openFileInNewTab(blob)
                        return {success: true};
                    },
                };

            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getPdfSettingsForInvoice: build.query({
            query: (customerId) => {
                return {
                    url: `/pdf-settings?customerId=${customerId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            }
        }),

    }),
});

export const {
    useGetPdfSettingsByCustomerIdQuery,
    useGetPdfImageByNameQuery,
    useUpdatePdfSettingsMutation,
    useLazyViewPdfImageQuery,
    useGetPdfSettingsForInvoiceQuery
} = pdfSettingsApi;
