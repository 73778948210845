import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const cannedInvoiceLinesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getAllInvoiceLinesByTenant: build.query({
            query: (tenant) => {
                return {
                    url: `/canned-invoice-lines/by-tenant?tenantId=${tenant}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        searchInvoiceLines: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/canned-invoice-lines/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['invoiceLines'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        createInvoiceLine: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/canned-invoice-lines`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['invoiceLines'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled,"Created","Invoice Line")
            },
        }),
        getInvoiceLineById: build.query({
            query: (id) => {
                return {
                    url: `/canned-invoice-lines/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['invoiceLineById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        updateInvoiceLine: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/canned-invoice-lines/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['invoiceLines', 'invoiceLineById','userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled,"Updated", "Invoice Line");
            },
        }),

        exportInvoiceLineData: build.query({
            query: (query) => {
                return {
                    url: `/canned-invoice-lines/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("invoiceLine"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    }),
});


export const {
    useGetAllInvoiceLinesByTenantQuery,
    useSearchInvoiceLinesQuery,
    useCreateInvoiceLineMutation,
    useGetInvoiceLineByIdQuery,
    useUpdateInvoiceLineMutation,
    useLazyExportInvoiceLineDataQuery
} = cannedInvoiceLinesApi;