import React, {useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useLazyExportRevenueEventDataQuery, useSearchRevenueEventsQuery} from "./revenueEventsApi";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {
    SearchSelectWithEnum,
} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {SearchDatePickerWrapper} from "../../../components/common/datatable/filter/SearchDatePickerWrapper";

const RevenueEvents = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Revenue Events", url: "/revenue-events"},
    ]);

    useEffect(() => {
        document.title = "Revenue Events";
    });

    const {data: enums} = useGetEnumsQuery("");
    const [triggerExportRevenueEventDataQuery] = useLazyExportRevenueEventDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Order Number",
            accessor: "orderNumber",
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'orderNumber'}
                    placeholder={'Enter Order Number...'}
                    name={'orderNumber'}/>
            }]
        },
        {
            label: "Product",
            accessor: "productType",
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["ProductType"],
                    "Select Product Type...",
                    false,
                    "productType",
                    null),
            }]
        },
        {
            label: "Amount",
            accessor: "amount",
        },
        {
            label: "Reason",
            accessor: "reason",
        },
        {
            label: "Created Date", accessor: "creationDate", cell: (props: any) => {
                return formatValueAsDate(props.value);
            }, filterOptions: [{
                filterComponent: <SearchDatePickerWrapper accessor={"createdDateFrom"}/>,
                overrideFilterAccessor: "createdDateFrom",
                overrideFilterLabel: "Created Date - From",
            }, {
                filterComponent: <SearchDatePickerWrapper accessor={"createdDateTo"}/>,
                overrideFilterAccessor: "createdDateTo",
                overrideFilterLabel: "Created Date - To",
            }]
        },

    ]

    return (
        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchRevenueEventsQuery,
                    exportAction: triggerExportRevenueEventDataQuery
                }}
                userSettingGroup={"RevenueEvents"}
            ></DataTable>


        </div>
    )
}
export default RevenueEvents;