import React, {FC} from "react";
import {DetailRow} from "../../../helpers/utils/OrderDetailUtils";
import {useGetCustomerByIdQuery} from "../../user/customer/customerApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetBranchByIdQuery} from "../../user/customer/branchApi";

interface props {
    orderQuery: { data: any, isLoading: boolean, isError: boolean } | any,
}

const OrderDetailInfo: FC<props> = ({orderQuery}) => {
    const {
        data: customerData,
        isLoading: isCustomerLoading,
        isError: isCustomerError
    } = useGetCustomerByIdQuery(orderQuery?.data?.customerId || skipToken);

    const {
        data: branchData,
        isLoading: isBranchLoading,
        isError: isBranchError,
    } = useGetBranchByIdQuery(orderQuery?.data?.branchId ? {
        branchId: orderQuery?.data?.branchId
    } : skipToken);

    return (
        <>
            <DetailRow label="Order"
                       value={orderQuery?.data?.orderNumber}
                       isLoading={orderQuery?.isLoading}
                       isError={orderQuery?.isError}/>
            <DetailRow label="Customer"
                       value={customerData?.customerName}
                       isLoading={isCustomerLoading}
                       isError={isCustomerError}/>
            <DetailRow label="Branch"
                       value={branchData?.displayLabel || branchData?.name}
                       isLoading={isBranchLoading}
                       isError={isBranchError}/>
        </>
    )

}
export default OrderDetailInfo;