import React, {useCallback, useMemo} from 'react';
import './App.css';
import {Routes as AppRoutes} from "./routes"
import {BrowserRouter as Router, Routes, Route, Outlet, Navigate} from 'react-router-dom';
import LoginPage from "./features/login/LoginPage";
import Footer from "./components/layout/footer/Footer";
import ForgotPassword from "./features/forgotPassword/ForgotPassword";
import Home from "./features/home/Home";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Header from "./components/layout/header/Header";
import Orders from './features/order/Orders';
import {isTokenExpired} from "./helpers/utils/AccessControlUtils";
import Users from './features/user/users/Users';

import CreateUser from './features/user/users/CreateUser';
import SideBar from './components/layout/sidebar/SideBar';
import UpdateUser from "./features/user/users/UpdateUser";
import {useDispatch, useSelector} from "react-redux";
import Roles from './features/user/roles/Roles';
import Teams from "./features/user/teams/Teams";
import Customers from "./features/user/customer/Customers";
import CannedNotes from "./features/data/cannedNotes/CannedNotes";
import Counties from "./features/data/counties/Counties";
import MetroAreas from "./features/data/metroAreas/MetroAreas";
import TenantPricing from "./features/accounting/tenantPricing/TenantPricing";
import Profile from "./features/profile/Profile";
import OrderDetailPage from './features/order/detailPage/OrderDetailPage';
import CreateOrder from "./features/order/wizard/CreateOrder";
import SystemProperties from "./features/sysAdmin/systemProperties/SystemProperties";
import UpdateSystemProperty from "./features/sysAdmin/systemProperties/UpdateSystemProperty";
import CreateCustomerForm from './features/user/customer/CreateCustomerForm';
import CustomerDetailPage from './features/user/customer/CustomerDetailPage';
import BranchDetailPage from './features/user/customer/branch/BranchDetailPage';
import FollowUpTypes from "./features/tenantAdmin/followUpTypes/FollowUpTypes";
import CreateFollowUpType from "./features/tenantAdmin/followUpTypes/CreateFollowUpType";
import UpdateFollowUpType from "./features/tenantAdmin/followUpTypes/UpdateFollowUpType";
import States from "./features/data/states/States";
import CreateState from "./features/data/states/CreateState";
import UpdateState from "./features/data/states/UpdateState";
import UpdateCannedNote from "./features/data/cannedNotes/UpdateCannedNote";
import CreateRole from './features/user/roles/CreateRole';
import UpdateRole from './features/user/roles/UpdateRole';
import CreateCounty from "./features/data/counties/CreateCounty";
import UpdateCounty from "./features/data/counties/UpdateCounty";
import CreateTeam from "./features/user/teams/CreateTeam";
import TeamDetailPage from "./features/user/teams/TeamDetailPage";
import CannedFileNames from "./features/data/cannedFileNames/CannedFileNames";
import CreateCannedFileName from "./features/data/cannedFileNames/CreateCannedFileName";
import UpdateCannedFileName from "./features/data/cannedFileNames/UpdateCannedFileName";
import PlatformPricing from "./features/accounting/platFormPricing/PlatformPricing";
import CreatePlatformPricing from "./features/accounting/platFormPricing/CreatePlatformPricing";
import UpdatePlatformPricing from "./features/accounting/platFormPricing/UpdatePlatformPricing";
import RevenueEvents from "./features/accounting/revenueEvents/RevenueEvents";
import CreateTenantPricing from "./features/accounting/tenantPricing/CreateTenantPricing";
import UpdateTenantPricing from "./features/accounting/tenantPricing/UpdateTenantPricing";
import PartnerPricing from "./features/accounting/partnerPricing/PartnerPricing";
import CreatePartnerPricing from "./features/accounting/partnerPricing/CreatePartnerPricing";
import UpdatePartnerPricing from "./features/accounting/partnerPricing/UpdatePartnerPricing";
import VendorPricing from "./features/accounting/vendorPricing/VendorPricing";
import CreateVendorPricing from "./features/accounting/vendorPricing/CreateVendorPricing";
import UpdateVendorPricing from "./features/accounting/vendorPricing/UpdateVendorPricing";
import CreateMetroArea from "./features/data/metroAreas/CreateMetroArea";
import UpdateMetroArea from "./features/data/metroAreas/UpdateMetroArea";
import ResetPassword from "./features/resetPassword/ResetPassword";
import CannedInvoiceLines from './features/data/cannedInvoiceLines/CannedInvoiceLines';
import CreateCannedInvoiceLine from "./features/data/cannedInvoiceLines/CreateCannedInvoiceLine";
import UpdateCannedInvoiceLine from './features/data/cannedInvoiceLines/UpdateCannedInvoiceLine';
import ErrorPage from "./components/common/ErrorPage";
import {clearGlobalError, setRedirectPath} from './app/globalSlice/globalSlice';
import FinCenReportingPage from "./features/external/FinCenReportingPage";
import SuccessPage from "./features/external/SuccessPage";
import BusinessTransactionFormPage from "./features/external/BusinessTransactionFormPage";
import IndividualsTransactionFormPage from "./features/external/IndividualsTransactionFormPage";
import SimplifileConfigs from "./features/sysAdmin/simplifileConfigs/SimplifileConfigs";
import CreateSimplifileConfig from "./features/sysAdmin/simplifileConfigs/CreateSimplifileConfig";
import UpdateSimplifileConfig from "./features/sysAdmin/simplifileConfigs/UpdateSimplifileConfig";
import VerifyMfaForm from "./features/login/modal/VerifyMfaForm";

function App() {
    const dispatch = useDispatch();
    const isCollapsed = useSelector((state: any) => state.global.isCollapsed);
    const error = useSelector((state: any) => state.global.error);

    const handleCloseError = useCallback(() => {
        dispatch(clearGlobalError());
    }, [dispatch]);

    const PublicRoutes = React.memo(() => {
        return (
            <>
                <Outlet/>
                <Footer/>
            </>
        );
    });

    const PrivateRoutes = () => {
        const dispatch = useDispatch();
        const isUserLoggedIn = useMemo(() => localStorage.getItem("authToken"), []);
        const isAuthenticated = useMemo(() => isUserLoggedIn && !isTokenExpired(), [isUserLoggedIn]);

        const renderContent = useCallback(() => {
            if (!isAuthenticated) {
                // Capture the attempted path and redirect to login
                dispatch(setRedirectPath(window.location.pathname + window.location.search + window.location.hash));
                return <Navigate to="/login"/>;
            }

            return (
                <div className="flex flex-col">
                    <Header />
                    <div className="app-content">
                        <SideBar />
                        <div className="flex flex-col flex-1 min-h-screen h-full">
                            <div
                                className={`pt-12 relative min-h-screen bg-surface-1 w-100 ${
                                    !isCollapsed ? "lg:pl-52 md:pl-52 sm:pl-0" : "pl-14"
                                }`}
                            >
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }, [dispatch, isAuthenticated, isCollapsed]);

        return renderContent();
    };


    return (
        <Router>
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                pauseOnHover
                pauseOnFocusLoss
                stacked
                theme='dark'
            />
            {error && (
                <div className="flex flex-col">
                    <Header/>
                    <div className="app-content">
                        <SideBar/>
                        <div className="flex flex-col flex-1 min-h-screen h-full">
                            <div
                                className={`pt-12 relative min-h-screen bg-surface-1 w-100  ${!isCollapsed ? "lg:pl-52 md:pl-52 sm:pl-0" : "pl-14"}`}>
                                <ErrorPage
                                    statusCode={error.statusCode}
                                    message={error.message}
                                    onClose={handleCloseError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {!error &&
                <Routes>
                    <Route element={<PublicRoutes/>}>
                        {!(localStorage.getItem("authToken") && !isTokenExpired()) && <Route path={AppRoutes.Default.path} element={<LoginPage/>}/>}
                        <Route path={AppRoutes.Login.path} element={<LoginPage/>}/>
                        <Route path={AppRoutes.VerifyMfa.path} element={<VerifyMfaForm/>}/>
                        <Route path={AppRoutes.ForgotPassword.path} element={<ForgotPassword/>}/>
                        <Route path={AppRoutes.ResetPassword.path} element={<ResetPassword/>}/>
                        <Route path={AppRoutes.CreatePassword.path} element={<ResetPassword/>}/>
                        <Route path={AppRoutes.Reporting.path} element={<FinCenReportingPage/>}/>
                        <Route path={AppRoutes.SuccessPage.path} element={<SuccessPage/>}/>
                        <Route path={AppRoutes.BusinessesTransactionPage.path}
                               element={<BusinessTransactionFormPage/>}/>
                        <Route path={AppRoutes.IndividualsTransactionPage.path}
                               element={<IndividualsTransactionFormPage/>}/>
                    </Route>

                    <Route element={<PrivateRoutes/>}>
                        {(localStorage.getItem("authToken") && !isTokenExpired()) && <Route path={AppRoutes.Default.path} element={<Home/>}/>}
                        <Route path={AppRoutes.Home.path} element={<Home/>}/>
                        <Route path={AppRoutes.Order.path} element={<Orders/>}/>
                        <Route path={AppRoutes.CreateOrder.path} element={<CreateOrder/>}/>
                        <Route path={AppRoutes.OrderDetailPage.path} element={<OrderDetailPage/>}/>
                        <Route path={AppRoutes.Users.path} element={<Users/>}/>
                        <Route path={AppRoutes.CreateUser.path} element={<CreateUser/>}/>
                        <Route path={AppRoutes.UpdateUser.path} element={<UpdateUser/>}/>
                        <Route path={AppRoutes.Roles.path} element={<Roles/>}/>
                        <Route path={AppRoutes.CreateRole.path} element={<CreateRole/>}/>
                        <Route path={AppRoutes.UpdateRole.path} element={<UpdateRole/>}/>
                        <Route path={AppRoutes.Teams.path} element={<Teams/>}/>
                        <Route path={AppRoutes.CreateTeam.path} element={<CreateTeam/>}/>
                        <Route path={AppRoutes.TeamDetailPage.path} element={<TeamDetailPage/>}/>
                        <Route path={AppRoutes.Customers.path} element={<Customers/>}/>
                        <Route path={AppRoutes.CreateCustomer.path} element={<CreateCustomerForm/>}/>
                        <Route path={AppRoutes.CustomerDetailPage.path} element={<CustomerDetailPage/>}/>
                        <Route path={AppRoutes.BranchDetailPage.path} element={<BranchDetailPage/>}/>
                        <Route path={AppRoutes.SystemProperties.path} element={<SystemProperties/>}/>
                        <Route path={AppRoutes.UpdateSystemProperties.path} element={<UpdateSystemProperty/>}/>
                        <Route path={AppRoutes.CannedNotes.path} element={<CannedNotes/>}/>
                        <Route path={AppRoutes.UpdateCannedNote.path} element={<UpdateCannedNote/>}/>
                        <Route path={AppRoutes.Counties.path} element={<Counties/>}/>
                        <Route path={AppRoutes.CreateCounty.path} element={<CreateCounty/>}/>
                        <Route path={AppRoutes.UpdateCounty.path} element={<UpdateCounty/>}/>
                        <Route path={AppRoutes.MetroAreas.path} element={<MetroAreas/>}/>
                        <Route path={AppRoutes.CreateMetroArea.path} element={<CreateMetroArea/>}/>
                        <Route path={AppRoutes.UpdateMetroArea.path} element={<UpdateMetroArea/>}/>
                        <Route path={AppRoutes.CannedFileNames.path} element={<CannedFileNames/>}/>
                        <Route path={AppRoutes.CreateCannedFileNames.path} element={<CreateCannedFileName/>}/>
                        <Route path={AppRoutes.UpdateCannedFileNames.path} element={<UpdateCannedFileName/>}/>
                        <Route path={AppRoutes.PlatformPricing.path} element={<PlatformPricing/>}/>
                        <Route path={AppRoutes.CreatePlatformPricing.path} element={<CreatePlatformPricing/>}/>
                        <Route path={AppRoutes.UpdatePlatformPricing.path} element={<UpdatePlatformPricing/>}/>
                        <Route path={AppRoutes.TenantPricing.path} element={<TenantPricing/>}/>
                        <Route path={AppRoutes.CreateTenantPricing.path} element={<CreateTenantPricing/>}/>
                        <Route path={AppRoutes.UpdateTenantPricing.path} element={<UpdateTenantPricing/>}/>
                        <Route path={AppRoutes.PartnerPricing.path} element={<PartnerPricing/>}/>
                        <Route path={AppRoutes.CreatePartnerPricing.path} element={<CreatePartnerPricing/>}/>
                        <Route path={AppRoutes.UpdatePartnerPricing.path} element={<UpdatePartnerPricing/>}/>
                        <Route path={AppRoutes.VendorPricing.path} element={<VendorPricing/>}/>
                        <Route path={AppRoutes.RevenueEvents.path} element={<RevenueEvents/>}/>
                        <Route path={AppRoutes.CreateVendorPricing.path} element={<CreateVendorPricing/>}/>
                        <Route path={AppRoutes.UpdateVendorPricing.path} element={<UpdateVendorPricing/>}/>
                        <Route path={AppRoutes.CannedInvoiceLines.path} element={<CannedInvoiceLines/>}/>
                        <Route path={AppRoutes.CreateCannedInvoiceLine.path} element={<CreateCannedInvoiceLine/>}/>
                        <Route path={AppRoutes.UpdateCannedInvoiceLine.path} element={<UpdateCannedInvoiceLine/>}/>
                        <Route path={AppRoutes.FollowUpTypes.path} element={<FollowUpTypes/>}/>
                        <Route path={AppRoutes.CreateFollowUpType.path} element={<CreateFollowUpType/>}/>
                        <Route path={AppRoutes.UpdateFollowUpType.path} element={<UpdateFollowUpType/>}/>
                        <Route path={AppRoutes.States.path} element={<States/>}/>
                        <Route path={AppRoutes.CreateState.path} element={<CreateState/>}/>
                        <Route path={AppRoutes.UpdateState.path} element={<UpdateState/>}/>
                        <Route path={AppRoutes.Profile.path} element={<Profile/>}/>
                        <Route path={AppRoutes.SimplifileConfig.path} element={<SimplifileConfigs/>}/>
                        <Route path={AppRoutes.UpdateSimplifileConfig.path} element={<UpdateSimplifileConfig/>}/>
                        <Route path={AppRoutes.CreateSimplifileConfig.path} element={<CreateSimplifileConfig/>}/>
                        <Route path={"*"} element={<ErrorPage statusCode={404}
                                                              message="Oops! The page you're looking for does not exist."
                                                              onClose={handleCloseError}/>}/>
                    </Route>
                </Routes>
            }
        </Router>
    );
}

export default App;
