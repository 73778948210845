// @ts-ignore
import logo from "../../../images/logo.svg";
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {FaBars} from "react-icons/fa6";
import "./header.modules.css";
import {useFindUserProfileInfoQuery} from "../../../features/profile/profileApi";
import {strApi} from "../../../app/strApi";
import {useDispatch} from "react-redux";
import {FaSearch, FaSync, FaTimes} from "react-icons/fa";
import SearchOrderModal from "../modal/SearchOrderModal";
import {useLazySearchProductsQuery} from "../../../features/order/productsApi";

const Header: React.FC = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [showOrderSearchModal, setShowOrderSearchModal] = useState(false);
    const [orderNumber, setOrderNumber] = useState<string>("");
    const [showNotification, setShowNotification] = useState(false);
    const {data: userProfileInfo} = useFindUserProfileInfoQuery("");
    const dispatch = useDispatch();
    const [triggerGlobalSearchProducts] = useLazySearchProductsQuery();

    const handleSearchOrder = async (e: React.FormEvent) => {
        e.preventDefault();

        if (orderNumber) {
            setShowLoader(true);
            try {
                // Trigger the query with the required data
                const response = await triggerGlobalSearchProducts({orderNumber}).unwrap();
                // Check response and handle logic
                if (response.data?.length === 1) {
                    window.location.href = `/orders/${response.data[0].orderId}#${response.data[0].id}`;
                } else {
                    setShowLoader(false);
                    setShowOrderSearchModal(true);
                }
            } catch (error) {
                console.error("Error fetching order:", error);
            }
        }
    };

    const globalOrderSearchBar = () => {
        return <div className="relative border-surface-4 text-text-1 bg-surface-2 rounded-md px-2 py-2 w-72">
            <input
                type="text"
                className="bg-surface-2 w-full outline-none"
                placeholder="Jump to order..."
                name="search"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        void handleSearchOrder(e);
                    }
                }}
            />

            {showLoader && <FaSync
                className="absolute top-3 fa-spin text-highlight-3 right-3"
                onClick={() => setOrderNumber("")}
            />}

            {orderNumber && !showLoader &&
                <FaTimes
                    className="absolute top-3 right-10 cursor-pointer"
                    onClick={() => setOrderNumber("")}
                />
            }


            {!showLoader &&
                <FaSearch className="absolute top-3 right-3 cursor-pointer hover:text-highlight-7"
                          onClick={(e) => {
                              void handleSearchOrder(e);
                          }}/>
            }
        </div>;
    }
    const notificationTray = () => {
        return <div className="relative ml-0">
            <button
                className="text-text-1 flex items-center notification_outer pt-2 lg:pt-0 sm:pt-2 hover:text-highlight-7"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => {
                    setShowNotification(!showNotification)
                }}>
                <i title='View notifications' className="fa fa-bell text-xl"/>
            </button>

            {showNotification &&
                <div
                    className="bg-surface-1 border border-surface-3 text-text-1 rounded-md absolute top-10 left-auto notification_wrapper">
                    <div
                        className="flex justify-between items-center px-4 py-1 border-b border-surface-2">
                        <div className="text-xl text-text-2 font-semibold">Notifications</div>
                    </div>
                    <div className="text-text-3 hover:bg-text-hover-2 px-4 py-3">No notifications to
                        display
                    </div>
                </div>
            }
        </div>;
    }
    const profileButton = () => {
        return <div className="pt-2 lg:pt-0 sm:pt-2">
            <button
                onClick={() => navigate("/profile")}
                className="d-inline flex items-center text-text-1"
                title='View user profile'>
                                    <span className="hover:text-highlight-7">
                                        <i className="fa fa-cog mr-2 text-lg"/>
                                        {userProfileInfo?.username}
                                    </span>
            </button>
        </div>;
    }
    const logoutButton = () => {
        return <div className="pt-2 lg:pt-0 sm:pt-2">
            <button
                className="items-center"
                onClick={() => {
                    localStorage.clear();
                    dispatch(strApi.util.resetApiState());
                    navigate("/login")
                }}>
                <i className="fas fa-sign-out-alt text-text-1 hover:text-highlight-7 text-lg"
                   title="Log out"/>
            </button>
        </div>;
    }
    return (
        <>
            <header
                className=" sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
                <div className="sm:flex justify-between lg:block md:flex flex items-center">
                    <Link to="/" className="flex items-center space-x-2">
                        <img src={logo} alt="Company Logo" className="h-10"/>
                    </Link>
                    <FaBars className="text-text-1 lg:hidden sm:block md:block" onClick={() => setShow(!show)}/>
                </div>
                <div
                    className={`navbar-collapse py-3 sm:py-3 lg:py-0 ms-auto lg:block  ${
                        show ? "block" : "hidden"
                    }`}
                >
                    <div className="right_nav items-center space-x-4 mr-2  sm:block md:block lg:flex gap-4">
                        {globalOrderSearchBar()}
                        {notificationTray()}
                        {profileButton()}
                        {logoutButton()}
                    </div>
                </div>
            </header>

            {showOrderSearchModal && <SearchOrderModal
                show={showOrderSearchModal}
                setShow={setShowOrderSearchModal}
                orderNumber={orderNumber}
                setOrderNumber={setOrderNumber}
            />
            }
        </>
    );
};

export default Header;
