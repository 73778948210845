import {strApi} from "../../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../../helpers/utils/Utils";

export const followUpTypesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        searchFollowUpTypes: build.query({
            query: (query) => {
                return {
                    url: `/follow-up-types/search?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["followUpTypes"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getFollowUpTypeById: build.query({
            query: (id) => {
                return {
                    url: `/follow-up-types/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["followUpTypeById"],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        updateFollowUpTypeId: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/follow-up-types/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ["followUpTypeById", "followUpTypes", "userAuditableInfo"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createFollowUpType: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/follow-up-types`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ["followUpTypes"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    })
})

export const {
    useSearchFollowUpTypesQuery,
    useGetFollowUpTypeByIdQuery,
    useUpdateFollowUpTypeIdMutation,
    useCreateFollowUpTypeMutation
} = followUpTypesApi