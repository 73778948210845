import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {
    useGetAllRegularCustomersQuery,
    useLazyGetCustomerByIdQuery,
} from "../../user/customer/customerApi";
import InputField from "../../../components/common/form/InputField";
import SelectField from "../../../components/common/form/SelectField";
import Button from "../../../components/common/form/Button";
import _ from "lodash";
import {useLazyGetBranchByIdQuery, useLazyGetBranchesForCustomerQuery} from "../../user/customer/branchApi";
import {useGetAllStatesQuery} from "../../data/states/statesApi";


export interface CreateOrderTabProps {
    createOrderData: any,
    setCreateOrderData: React.Dispatch<React.SetStateAction<any>>,
    setTab: React.Dispatch<React.SetStateAction<number>>,
}

const OrderDetailsTab: FC<CreateOrderTabProps> = ({createOrderData, setCreateOrderData, setTab}) => {

    const allRegularCustomersResult = useGetAllRegularCustomersQuery("");
    const [getBranchesForCustomer, branchesForCustomerResult,] = useLazyGetBranchesForCustomerQuery();
    const [customerById] = useLazyGetCustomerByIdQuery();
    const [getBranchById, branchById] = useLazyGetBranchByIdQuery();
    const {data: enums} = useGetEnumsQuery("");
    const [productTypeEnums, setProductTypeEnums] = useState([]);
    const allStatesApiResult = useGetAllStatesQuery("");

    useEffect(() => {
        if (createOrderData.customerId) {
            getBranchesForCustomer({pageSize: 100, customerId: createOrderData.customerId?.value})
            customerById(createOrderData.customerId?.value)
        }
    }, [createOrderData.customerId]);

    useEffect(() => {
        if (!createOrderData?.branchId) {
            setCreateOrderData({
                ...createOrderData,
                productType: null,
            })

            setProductTypeEnums([]);
        }

        if (createOrderData.branchId && createOrderData.customerId) {
            setProductTypeEnums([]);
            getBranchById({branchId: createOrderData.branchId?.value})
        }


    }, [createOrderData.branchId, createOrderData.customerId]);

    useEffect(() => {
        let customerList = allRegularCustomersResult?.data?.filter((el: any) => !el.parentCustomer);
        if (customerList?.length !== 1) return;

        createOrderData['customerId'] = {
            label: customerList[0]?.customerName,
            value: customerList[0]?.id
        }
        setCreateOrderData({
            ...createOrderData,
        })

    }, [allRegularCustomersResult]);

    useEffect(() => {
        if (branchesForCustomerResult?.data?.data?.length !== 1) return;

        createOrderData['branchId'] = {
            label: branchesForCustomerResult?.data?.data[0]?.name,
            value: branchesForCustomerResult?.data?.data[0]?.id
        }
        setCreateOrderData({
            ...createOrderData,
        })

    }, [branchesForCustomerResult]);

    useEffect(() => {
        if (!branchById?.data) {
            return;
        }

        // @ts-ignore
        setProductTypeEnums(_.map(branchById?.data.availableProduct, (curValue) => (
            {
                displayName: enums?.["ProductType"].filter((el: any) => el.constant === curValue)[0]?.displayName,
                constant: enums?.["ProductType"].filter((el: any) => el.constant === curValue)[0]?.constant
            }
        )));

        if (branchById?.data.availableProduct.length !== 1) return;

        let productType = {
            "label": enums?.["ProductType"].filter((el: any) => el.constant === branchById?.data.availableProduct[0])[0]?.displayName,
            "value": enums?.["ProductType"].filter((el: any) => el.constant === branchById?.data.availableProduct[0])[0]?.constant
        }

        let state = null;
        if (branchById?.data.preferredState){
             state = {
                "label": allStatesApiResult?.data?.filter((el: any) => el.id === branchById?.data.preferredState)[0]?.stateName,
                "value": branchById?.data.preferredState
            }
        }

        setCreateOrderData({
            ...createOrderData,
            productType: productType,
            state: state
        })

    }, [branchById, allStatesApiResult?.data]);



    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: string }) => {

        const {name, value} = "target" in e ? e.target : e;
        setCreateOrderData({
            ...createOrderData,
            [name]: value,
            // If customer is de-selected it should empty the branch field too
            ...(name === "customerId" ? {"branchId": null} : {})
        })
    }

    const isNextButtonEnabled = () => {
        return ["customerOrderNumber", "customerId", "branchId", "productType"]?.every(cur => !isNullOrEmpty(createOrderData?.[cur]))
    }

    return (
        <>
            <InputField
                label="Order No"
                value={createOrderData?.customerOrderNumber}
                placeholder="Enter Order No..."
                type="text"
                name={"customerOrderNumber"}
                onChange={handleOnChange}
                required={true}
            />

            <SelectField
                label="Customer"
                value={createOrderData?.customerId}
                placeholder="Select Customer..."
                options={createOptionListForSelectTag(allRegularCustomersResult?.data?.filter((el: any) => !el.parentCustomer), "customerName", "id", "customerNumber")}
                onChange={(selectedOption: any) => handleOnChange({name: 'customerId', value: selectedOption})}
                required={true}
            />

            <SelectField
                label="Branch"
                value={createOrderData?.branchId}
                placeholder="Select Branch..."
                options={createOptionListForSelectTag(branchesForCustomerResult?.data?.data, "name", "id","","displayLabel")}
                onChange={(selectedOption: any) => handleOnChange({name: 'branchId', value: selectedOption})}
                required={true}
                isLoading={isNullOrEmpty(createOrderData?.customerId) || branchesForCustomerResult.isLoading || branchesForCustomerResult.isFetching}
            />

            <SelectField
                label="Product"
                value={createOrderData?.productType}
                placeholder="Select Product Type..."
                options={createOptionListForSelectTag(productTypeEnums, "displayName", "constant")}
                onChange={(selectedOption: any) => handleOnChange({name: 'productType', value: selectedOption})}
                required={true}
                isDisabled={!productTypeEnums || productTypeEnums.length === 0}
            />

            <div className={"flex justify-end text-end mt-4"}>
                <Button btnText={"Next"} isLoading={false} type={isNextButtonEnabled() ? "submit" : "button"}
                        onClick={() => {
                            setTab(2)
                        }}
                        isDisabled={!isNextButtonEnabled()}
                />
            </div>
        </>
    )
}
export default OrderDetailsTab