import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../helpers/utils/RtkQueryUtils";

export const ordersApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        // Define the endpoint for searching orders
        getOrderById: build.query({
            query: (orderId) => {
                return {
                    url: `/orders/${orderId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createOrder: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/orders`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Order")
            },
        }),
        getContactEmailById: build.query({
            query: (orderId) => {
                return {
                    url: `/orders/contact-emails/${orderId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    }),
});

export const {
    useGetOrderByIdQuery,
    useCreateOrderMutation,
    useGetContactEmailByIdQuery
} = ordersApi;