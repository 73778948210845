import React, { FC, useEffect, useState } from "react";
import {
    useFindUserSettingsByGroupNameQuery,
    useUpdateUserSettingsMutation,
} from "../userSetting/userSettingsApi";
import { modifyPageSizeSetting } from "../userSetting/UserSettingUtils";
import { UserSetting } from "../userSetting/UserSettingTypes";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

interface PaginationProps {
    displayText: string;
    pageCount: number;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    groupName: string;
    setExternalPageSize: (pageSize: number) => void;
    pagesAfterEllipsis?: number;
}

const Pagination: FC<PaginationProps> = ({
                                             displayText,
                                             pageCount,
                                             pageNumber,
                                             setPageNumber,
                                             groupName,
                                             setExternalPageSize,
                                             pagesAfterEllipsis = 3,
                                         }) => {
    const userSettingsQuery = useFindUserSettingsByGroupNameQuery(groupName);
    const [updateUserSettings] = useUpdateUserSettingsMutation();

    const [pageSize, setPageSize] = useState(() => {
        const initialValue = getPageSizeSettingValue(userSettingsQuery?.data);
        return initialValue ? initialValue.toString() : "15";
    });

    useEffect(() => {
        setExternalPageSize(parseInt(pageSize, 10));
    }, [pageSize, setExternalPageSize]);

    function getPageSizeSettingValue(settings?: UserSetting[]) {
        return (
            settings?.find(
                (s) => s.group === groupName && s.key === "pageSize"
            )?.value || "15"
        );
    }

    function changePage(page: number) {
        setPageNumber(page);
    }

    async function changePerPageSize(size: number) {
        if (userSettingsQuery?.data) {
            await updateUserSettings(
                modifyPageSizeSetting(groupName, userSettingsQuery?.data, size)
            );
            setPageSize(size.toString());
        }
    }

    function renderPageNumbers() {

        const startPage = pageCount > 6 ? Math.max(0, pageNumber - pagesAfterEllipsis) : 0;
        const endPage = pageCount > 6 ? Math.min(pageCount, pageNumber + pagesAfterEllipsis + 1) : pageCount;

        return [...Array(pageCount).keys()]
            .slice(startPage, endPage)
            .map((num) => (
                <button
                    key={num}
                    onClick={() => changePage(num)}
                    aria-current={num === pageNumber ? "page" : undefined}
                    className={`px-3 py-1 rounded-full ${
                        num === pageNumber
                            ? "bg-highlight-3 text-text-1 hover:bg-highlight-7"
                            : "hover:text-highlight-7 text-text-5"
                    }`}
                >
                    {num + 1}
                </button>
            ));
    }

    return (
        <div className="flex items-center justify-between my-2">
            <div className="space-x-2 flex items-center">
                {displayText && (
                    <span className="text-text-2 pr-2">
            {pageCount > 0 ? displayText : ""}
          </span>
                )}

                <button
                    onClick={() => changePage(pageNumber - 1)}
                    disabled={pageNumber === 0}
                    aria-label="Previous Page"
                    className={`px-3 py-2.5 rounded-full text-text-5 shadow-custom ${
                        pageNumber === 0
                            ? "cursor-not-allowed"
                            : "hover:text-highlight-7"
                    }`}
                >
                    <FaArrowLeft />
                </button>

                {pageCount > pagesAfterEllipsis + 3 && pageNumber > pagesAfterEllipsis && (
                    <>
                        <button
                            onClick={() => changePage(0)}
                            className="px-3 py-1 hover:text-highlight-7 text-text-5 rounded-full"
                        >
                            1
                        </button>
                        {pageNumber > pagesAfterEllipsis + 1 && (
                            <span className="text-text-5">...</span>
                        )}
                    </>
                )}

                {renderPageNumbers()}

                {pageCount > pagesAfterEllipsis + 3 &&
                    pageNumber < pageCount - pagesAfterEllipsis - 1 && (
                        <>
                            {pageNumber < pageCount - pagesAfterEllipsis - 2 && (
                                <span className="text-text-5">...</span>
                            )}
                            <button
                                onClick={() => changePage(pageCount - 1)}
                                className="px-3 py-1 hover:text-highlight-7 text-text-5 rounded-full"
                            >
                                {pageCount}
                            </button>
                        </>
                    )}

                <button
                    onClick={() => changePage(pageNumber + 1)}
                    disabled={pageNumber + 1 >= pageCount}
                    aria-label="Next Page"
                    className={`px-3 py-2.5 rounded-full text-text-5 shadow-custom ${
                        pageNumber + 1 >= pageCount
                            ? "cursor-not-allowed"
                            : "hover:text-highlight-7"
                    }`}
                >
                    <FaArrowRight />
                </button>
            </div>

            {userSettingsQuery?.data && (
                <div className="ml-4 flex items-center space-x-2">
                    {[15, 25, 50, 100, 150].map((size) => (
                        <button
                            key={size}
                            onClick={() => changePerPageSize(size)}
                            className={`px-3 py-2 rounded-full ${
                                pageSize === size.toString()
                                    ? "bg-highlight-3 text-text-1 hover:bg-highlight-7"
                                    : "hover:text-highlight-7 text-text-5"
                            }`}
                        >
                            {size}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Pagination;
