// @ts-ignore
import moment from "moment/moment";
// @ts-ignore
import _ from "lodash";
import {Link} from "react-router-dom";
import {isNullOrEmpty} from "../../../helpers/utils/Utils";
import {DataTableColumn} from "./DataTableTypes";
import {UserSetting} from "../userSetting/UserSettingTypes";
import {SearchSelectWithList} from "./filter/SearchSelectWrapper";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import React from "react";


// Returns the given phoneNumber with all non-digits removed
export function getUnformattedPhoneNumber(phoneNumber: string) {
    return phoneNumber ? phoneNumber.replace('(', '').replace(')', '').replace('-', '').replace(' ', '') : null;
}


// @ts-ignore
export function queryParamsToObject(input) {
    const payload = {};
    const params = new URLSearchParams(input);

    for (let [key, val] of params.entries()) {
        if (key.endsWith('[]')) {
            key = key.replace(/\[\]$/, '');
            // @ts-ignore
            if (payload[key]) {
                // @ts-ignore
                payload[key].push(val);
            } else {
                // @ts-ignore
                payload[key] = [val]
            }
        } else {
            if (val === 'true' || val === 'false') {
                // @ts-ignore
                payload[key] = val === 'true';
            } else {
                // @ts-ignore
                payload[key] = val;
            }
        }
    }

    return payload;
}

export function objectToQueryParams(input: Object) {
    const params = new URLSearchParams();

    for (const key in input) {
        // @ts-ignore
        if (Array.isArray(input[key])) {
            // @ts-ignore
            input[key].forEach(val => {
                params.append(key + '[]', val)
            })
        } else {
            // @ts-ignore
            params.append(key, input[key]);
        }
    }

    return '?' + params.toString();
}

// @ts-ignore
// TODO: This whole method needs to be rewritten
export function getFinalizedColumns(allColumns, userSettings) {
    copyCellValueToInternalProperty(allColumns);

    if (!userSettings || userSettings.length === 0) {
        return allColumns;
    }

    // Filter out user-hidden columns
    allColumns = allColumns.filter((column: DataTableColumn) =>
        !userSettings.some((s: UserSetting) => s.key === column.label && s.value == 'hide'));

    return allColumns;
}


export const getValueOrDefault = (value: any, defaultValue: any) => {
    if (isNullOrEmpty(value)) {
        return defaultValue;
    }

    return value;
}

// Returns a clickable <a> that adds the given path to the history, and renders the given text
export const createClickableLink = (path = "", text = "", userSetting: any, isIcon = false, className = "", permissions: string[] = []) => {
    // Check if the user has the required permissions
    const clickable = hasPermission(permissions,true);

    let target = '_self'
    if (userSetting) {
        const linkOpeningStyleObject = userSetting?.find((item: any) => item.key === 'linkOpeningStyle');
        if (linkOpeningStyleObject && linkOpeningStyleObject.value === 'NEW TAB') {
            target = '_blank';
        }
    }
    return (
        <>
            {
                isIcon ?
                    clickable ? <Link
                        className={className}
                        to={`${path}`}
                        target={target}
                        rel={target === '_blank' ? 'noopener noreferrer' : ''}
                    /> : <i className={className}/>
                    :
                    clickable ? <Link
                        className="text-highlight-6 hover:text-highlight-7 cursor"
                        to={`${path}`}
                        target={target}
                        rel={target === '_blank' ? 'noopener noreferrer' : ''}
                    >
                        {text}
                    </Link> : <span>{text}</span>
            }
        </>
    );
}

export const getHeaderByName = (headerGroups: any, headerName: string) => {
    return headerGroups.length && headerGroups[0].headers ? headerGroups[0].headers.find((c: DataTableColumn) => c.label == headerName) : null;
}

export const shouldHideFromUser = (disallowedCustomerLevels: string[] | undefined, customerLevel: string) => disallowedCustomerLevels?.includes(customerLevel);


export const bindDependents = (
    column: DataTableColumn,
    allColumns: DataTableColumn[],
    refFilterQuery: any,
    setPendingQuery: Function
) => {
    if (!column || !column.filterOptions) return;

    for (let filterOptions of column.filterOptions) {


        if (!filterOptions || !Object.keys(filterOptions).length) continue;

        const hasDependents = filterOptions?.dependentColumns && filterOptions?.dependentColumns?.length;


        if (!hasDependents) continue;

        for (let dependentColumn of allColumns.filter((el: any) => el.filterOptions)) {
            for (let dependentFilterOptions of dependentColumn?.filterOptions || []) {
                if (!dependentFilterOptions?.onDependencyUpdated) continue;
                if (!filterOptions.dependentColumns?.includes(dependentColumn?.label)) continue;


                if (filterOptions?.filterComponent) {
                    const existingComponent = filterOptions.filterComponent;

                    // Create a new `onChange` handler
                    const onChangeHandler = (event: any) => {

                        const tempQuery: any = { ...refFilterQuery.current };

                        if (!event) {
                            // @ts-ignore
                            delete tempQuery[column?.filterOptions[0]?.overrideFilterAccessor || column.accessor];
                        } else {
                            // @ts-ignore
                            tempQuery[column?.filterOptions[0]?.overrideFilterAccessor || column.accessor] = event;
                        }

                        // @ts-ignore
                        setPendingQuery(tempQuery);

                        allColumns.forEach((currentColumn: any) => {

                            if (filterOptions.dependentColumns?.includes(currentColumn.label)) {

                                currentColumn.filterOptions?.forEach((cur: any) => {
                                    if (cur?.onDependencyUpdated) {
                                        cur.onDependencyUpdated(event);
                                    }
                                    delete tempQuery[cur?.filterComponent?.props?.accessor];
                                });

                                setPendingQuery(tempQuery);
                            }
                        });
                    };

                    // Clone the component and override its `onChange` prop
                    filterOptions.filterComponent = React.cloneElement(existingComponent, {
                        ...existingComponent.props,
                        onChange: onChangeHandler,
                    });

                }
            }
        }
    }
};

// Copy our lowercase c property to the forced uppercase C property... the React Table library makes this necessary.
// It's conditional because we want to use the default value of the library's uppercase C when we don't have a lowercase c specified :)
export const copyCellValueToInternalProperty = (columns: DataTableColumn[]) => {
    columns?.forEach((c: any) => {
        if (!c.cell) {
            return;
        }

        c.Cell = c.cell;
    });
};

export function SearchSelectWithIndeterminateBoolean(placeholderText: any, accessor: any, trueLabel = "True", falseLabel = "False", quickFilters = null, isConstantData = true) {
    return SearchSelectWithList([
        {label: trueLabel, value: true},
        {label: falseLabel, value: false}
    ], "label", "value", placeholderText, false, accessor, null, quickFilters, isConstantData);
}

export function applyDefaultsToObject(o: any, defaultO: any) {
    if (!o || !defaultO || typeof o !== 'object' || typeof defaultO !== 'object') {
        return o;
    }

    o = {...defaultO, ...o};

    for (const elementName of Object.keys(defaultO)) {
        o[elementName] = applyDefaultsToObject(o[elementName], defaultO[elementName])
    }
    return o;
}


export function getDataTablePropsDefault() {
    return {
        defaultSortOptions: {
            accessor: 'creationDate',
            direction: 'desc'
        },

        // All options related to row selection. Defaults to default RowSelectionOption.
        rowSelectionOptions: {
            // Whether rows can be selected for this table. Defaults to false.
            enableRowSelection: false,

            // Function that takes in the selected rows and the row that was clicked, and handles the event.
            // The function signature is (selectedRows: Set<any>, targetRow: any) => void.
            onSelectionChange: (selectedRows: Set<any>, targetRow: any) => {
            },

            // Whether multiple rows can be selected for this table. 'enableRowSelection' must be true for this to be evaluated. Defaults to false.
            enableMultiRowSelection: false,

            // Function that takes in a row, evaluates it, and returns whether it should be selectable. If unset, all rows will be selectable.
            rowSelectionPredicate: (row: any) => true,

            // The accessor to use when storing rows in the rowSelectionState. Defaults to "id".
            rowIdAccessor: 'id',

            // The extra styles to apply when a row is selected.
            rowSelectedStyle: null,

            // The extra styles to apply when a row is not selectable.
            rowUnselectableStyle: null
        },

        // Defines the buttons, and their behavior, that are placed above the table. Defaults to null.
        buttonOptions: null,

        // Defines the sections that should be rendered. Defaults to empty, resulting in everything being rendered. Defaults to null.
        renderOptions: null,

        // Name of the group of settings within user settings for this table. Defaults to null.
        userSettingGroup: null,

        // Default filter to apply to this table. Defaults to null.
        defaultFilter: null,

        // Default value of filterFormExpanded. If true, the filterForm will be expanded when the DataTable is first loaded. Defaults to false.
        defaultFilterFormExpanded: false,

        // Whether to append the filter parameters to the URL. Usually this will be disabled for modals or otherwise embedded tables. Defaults to true.
        appendFilterToUrl: true,

        // The base permission required to access this data i.e "ORDR","USR". Defaults to null.
        basePermission: null
    }
}