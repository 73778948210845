import {strApi} from "../../../app/strApi";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {
    getAuthorizationHeader, getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const metroAreasApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        searchMetroAreas: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/metro-areas/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['metroAreas'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createMetroArea: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/metro-areas`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['metroAreas'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Metro Area")
            },
        }),

        getMetroAreaById: build.query({
            query: (id) => {
                return {
                    url: `/metro-areas/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['metroAreaById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateMetroArea: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/metro-areas/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['metroAreas', 'metroAreaById','userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Metro Area")
            },
        }),

        exportMetroAreaData: build.query({
            query: (query) => {
                return {
                    url: `/metro-areas/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("metroAreas"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    })
});

export const {
    useSearchMetroAreasQuery,
    useCreateMetroAreaMutation,
    useUpdateMetroAreaMutation,
    useGetMetroAreaByIdQuery,
    useLazyExportMetroAreaDataQuery
} = metroAreasApi;