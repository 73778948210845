import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {ModalProps} from "../../../helpers/constants";
import Modal from "../../../components/common/form/Modal";
import Form from "../../../components/common/form/Form";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useDeliverFileMutation, useGetDeliveryModalDocumentsQuery, useLazyViewFileQuery,} from "../orderFilesApi";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import MultiFreeFormField from "../../../components/common/form/MultiFreeFormField";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetContactEmailByIdQuery} from "../ordersApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {createOptionTypeListFromArray} from "../../../helpers/utils/Utils";
import {useGetBranchByIdQuery} from "../../user/customer/branchApi";
import InputField from "../../../components/common/form/InputField";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface Props extends ModalProps {
    productId: any;
    orderId: any;
    orderQuery: any;
}

const DocumentDeliverModal: FC<Props> = ({show, setShow, orderId, productId, orderQuery}) => {
    const [selectedDocIds, setSelectedDocIds] = useState<any[]>([]);
    const [deliveryEmails, setDeliveryEmails] = useState<string[]>([]);
    const [fileId, setFileId] = useState<string | null>(null);
    const [downloadFile, setDownloadFile] = useState<boolean>(true);
    const [deliveryFiles, {isLoading}] = useDeliverFileMutation();
    const [viewFile, {isFetching}] = useLazyViewFileQuery();
    const [downloadFileStart, setDownloadFileStart] = useState<boolean>(false);

    const {data: documentData} = useGetDeliveryModalDocumentsQuery({
        orderFileType: ["Other", "Recorded", "Invoice"],
        orderId,
        productId,
    });

    const {data: branchData} = useGetBranchByIdQuery(
        orderQuery?.data?.branchId ? {
            branchId: orderQuery?.data?.branchId,
        } : skipToken);

    const {data: contactData} = useGetContactEmailByIdQuery(orderQuery?.data?.id ?? skipToken);

    useEffect(() => {
        if (documentData?.data?.length > 0) {

            let defaultSelectedIds = new Set();

            // Find all invoices and the newest invoice
            const invoices = documentData.data.filter((el: any) => el.fileType === "Invoice");

            // Add the newest invoice ID if it exists
            if (invoices.length) {
                defaultSelectedIds.add(invoices[0].id);
            }

            // Add all non-invoice document IDs
            documentData.data
                .filter((el: any) => el.fileType !== "Invoice")
                .forEach((el: any) => {
                    defaultSelectedIds.add(el.id);
                });

            setSelectedDocIds(Array.from(defaultSelectedIds));
        }
    }, [documentData]);

    useEffect(() => {
        if (selectedDocIds.length > 0) {
            setFileId(selectedDocIds[0]);
        }
    }, [selectedDocIds])

    useEffect(() => {
        if (contactData && contactData.length > 0) {
            setDeliveryEmails(contactData);
        }
    }, [contactData]);

    useEffect(() => {
        if (fileId) {
            viewFile({fileId, isDownload: false, isOpenInFrame: true});
        }
    }, [fileId]);

    const handleRowSelection = (selectedRows: any, row: any) => {
        let selectedIds = Array.from(selectedRows);
        setSelectedDocIds(selectedIds);
        setFileId(row.original.id);
    };

    const handleDeliveryEmailsChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {value} = "target" in e ? e.target : e;
        setDeliveryEmails(value);
    };

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "fileName",
            canSort: false,
        },
        {
            label: "Type",
            accessor: "fileType",
        },
        {
            label: "Delivered",
            accessor: "dateDelivered",
            cell: (props: any) => formatValueAsDate(props.row.original.dateDelivered),
        },
    ];

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        await handleRTKQuery(async () => {
                let files = selectedDocIds.map((id) => documentData?.data?.find((doc: any) => doc.id === id));
                await deliveryFiles({
                    orderId: orderId,
                    deliveryEmail: deliveryEmails,
                    files: files,
                }).unwrap();
            },
            () => {
                if (!downloadFile) {
                    setShow(false);
                    setSelectedDocIds([]);
                    setDeliveryEmails([]);
                } else {
                    setDownloadFileStart(true);
                }
            }
        );

        if (downloadFile) {
            // Trigger file download requests and wait for all to complete
            try {
                await Promise.all(
                    selectedDocIds.map((el: any) =>
                        viewFile({fileId: el, isDownload: true}).unwrap() // Assuming viewFile returns a promise
                    )
                );
            } catch (error) {
                console.error("Error downloading files:", error);
            }

            // After all downloads complete, close the modal and reset state
            setShow(false);
            setDownloadFileStart(false);
            setSelectedDocIds([]);
            setDeliveryEmails([]);
        }
    };

    return (
        <Modal
            title="Download on delivery"
            show={show}
            setShow={setShow}
            className="mx-4"
            fields={
                <Form onSubmit={handleSubmit} fields={
                    <>
                        <p className="ml-1 text-text-1">Select the documents you would like to deliver</p>
                        <div className="md:grid sm:block grid-cols-12 gap-3">
                            <div className="col-span-5">
                                <DataTable
                                    columns={columns}
                                    defaultFilter={{
                                        orderFileType: ["Other", "Recorded", "Invoice"],
                                        orderId,
                                        productId,
                                    }}
                                    actions={{
                                        fetchAction: useGetDeliveryModalDocumentsQuery,
                                    }}
                                    renderOptions={{
                                        skipUserSettingRender: true,
                                    }}
                                    appendFilterToUrl={false}
                                    rowSelectionOptions={{
                                        enableRowSelection: true,
                                        onSelectionChange: handleRowSelection,
                                        enableMultiRowSelection: true,
                                        rowIdAccessor: "id",
                                        defaultSelectedIds: selectedDocIds
                                    }}
                                />
                            </div>
                            <div className="col-span-7">
                                <iframe
                                    src={""}
                                    style={{width: "100%", minHeight: "500px", overflow: "visible"}}
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        </div>

                        <div className="border-t border-surface-3">
                            {branchData && branchData.emailNotificationMode !== "OFF" && (
                                <MultiFreeFormField
                                    defaultOptions={createOptionTypeListFromArray(deliveryEmails)}
                                    placeholder="abc@org.com..."
                                    onChange={handleDeliveryEmailsChange}
                                    name="email"
                                    label="Deliver to"
                                    required={branchData?.emailNotificationMode !== "OFF"}
                                />
                            )}

                            <div className={"mt-2"}>
                                <InputField
                                    type={"checkbox"}
                                    label={"Download after delivery?"}
                                    checked={downloadFile}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setDownloadFile(!downloadFile);
                                    }}
                                />
                            </div>


                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => setShow(false),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Deliver",
                                            type: "submit",
                                            isLoading: isLoading || (downloadFileStart && isFetching),
                                            isDisabled: branchData?.emailNotificationMode === "OFF" ? selectedDocIds.length === 0 : selectedDocIds.length === 0 || (deliveryEmails.length === 0 && !downloadFile)
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align="end"
                                gap={2}
                            />

                        </div>
                    </>
                }/>
            }
        />
    );
};

export default DocumentDeliverModal;
