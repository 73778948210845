import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../../helpers/utils/Utils";

export const simplifileConfigsApiApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchSimplifileConfigs: build.query({
            query: (query) => {
                return {
                    url: `/simplifile-configs/search?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["simplifileConfigs"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getSimplifileConfigById: build.query({
            query: (id) => {
                return {
                    url: `/simplifile-configs/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["simplifileConfigById"],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        updateSimplifileConfigById: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/simplifile-configs/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ["simplifileConfigById", "simplifileConfigs", "userAuditableInfo"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Simplifile Config")
            },
        }),

        createSimplifileConfig: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/simplifile-configs`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ["simplifileConfigs"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Simplifile Config")
            },
        }),

    })
})

export const {
    useSearchSimplifileConfigsQuery,
    useGetSimplifileConfigByIdQuery,
    useUpdateSimplifileConfigByIdMutation,
    useCreateSimplifileConfigMutation
} = simplifileConfigsApiApi