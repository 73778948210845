import React, {FC} from "react";
import {ModalProps} from "../../../helpers/constants";
import Modal from "../../common/form/Modal";
import ButtonTray from "../../common/form/ButtonTray";

interface ConfirmationModalProps extends ModalProps {
    title: string;
    bodyMessage: string;
    onSubmit: any;
    cancelBtnTxt?: string;
    confirmBtnTxt?: string;
    isLoading: boolean;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
                                                           show,
                                                           setShow,
                                                           title = "",
                                                           bodyMessage = "",
                                                           onSubmit,
                                                           cancelBtnTxt = "Cancel",
                                                           confirmBtnTxt = "Confirm",
                                                           isLoading = false,
                                                       }) => {
    return (
        <Modal
            title={title}
            show={show}
            setShow={setShow}
            className={"w-2/5 mx-2"}
            fields={
                <>
                    <h3 className="mb-5 text-base font-normal text-text-1">{bodyMessage}</h3>

                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    btnText: cancelBtnTxt,
                                    onClick: () => setShow(false),
                                    type: "close",
                                },
                                buttonType: "button"
                            },
                            {
                                buttonProps: {
                                    btnText: confirmBtnTxt,
                                    onClick: async (e: any) => {
                                        e.preventDefault()
                                        await onSubmit(e)
                                        setShow(false)
                                    },
                                    type: "submit",
                                    isLoading: isLoading,
                                },
                                buttonType: "button",

                            }
                        ]}
                        align={"end"}
                        gap={2}
                    />

                </>
            }
        />
    );
};

export default ConfirmationModal;
