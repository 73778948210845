import {strApi} from "../../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../../helpers/utils/RtkQueryUtils";

export const tenantApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getAllTenants: build.query({
            query: () => {
                return {
                    url: `/tenants`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
})

export const {
    useGetAllTenantsQuery,
    useLazyGetAllTenantsQuery

} = tenantApi