import {ModalProps} from "../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {
    useGetProductPricingByIdQuery,
    useUpdateProductPricingMutation
} from "../productsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import Modal from "../../../components/common/form/Modal";
import Form from "../../../components/common/form/Form";
import {getFirstNonNull, showToast} from "../../../helpers/utils/Utils";
import InputField from "../../../components/common/form/InputField";
import Button from "../../../components/common/form/Button";

interface props extends ModalProps {
    pricingId: string | null | undefined
}

const UpdatePricingModal: FC<props> = ({show, setShow, pricingId}) => {

    const [pricingData, setPricingData] = useState<any>({});

    const pricingQuery = useGetProductPricingByIdQuery(pricingId || skipToken);

    useEffect(() => {
        if (!pricingQuery?.data) return;
        setPricingData({...pricingQuery?.data});
    }, [pricingQuery?.data]);

    const [updateProductPricingApi, updateProductPricingApiResponse] = useUpdateProductPricingMutation();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                return await updateProductPricingApi({pricingId: pricingId, body: pricingData}).unwrap();
            },
            () => {
                showToast('Successfully updated pricing!', 'success');
            },
            () => {
                showToast('Failed to update pricing, please try again later!', 'error');
            },
            () => {
                setShow(false);
            }
        );
    }

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | { name: string; value: any }
    ) => {
        const {name, value} = "target" in e ? e.target : e;
        setPricingData((prev: any) => ({...prev, [name]: value}));
    };

    return (
        <>
            <Modal
                title={'Update Pricing'}
                show={show}
                setShow={setShow}
                className="w-3/5 mx-4"
                fields={
                    <Form onSubmit={handleSubmit} fields={
                        <>
                            <InputField
                                name='overridePrice'
                                label='Price'
                                value={getFirstNonNull([pricingData.overridePrice, pricingData.calculatedPrice, pricingData.basePrice])}
                                placeholder="Enter price..."
                                onChange={handleOnChange}
                                required={false}
                                type={'number'}
                            />

                            <InputField
                                name={'overrideVendorFee'}
                                label='Vendor Fee'
                                value={getFirstNonNull([pricingData?.overrideVendorFee, pricingData?.calculatedVendorFee, pricingData?.baseVendorFee])}
                                placeholder="Enter price..."
                                onChange={handleOnChange}
                                required={false}
                                type={'number'}
                            />

                            <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                                <Button btnText={"Close"} isLoading={false} type={"close"}
                                        onClick={() => setShow(false)}/>
                                <Button btnText={"Submit"} isLoading={updateProductPricingApiResponse.isLoading}
                                        type={"submit"}/>
                            </div>
                        </>
                    }/>
                }
            />
        </>
    )
}

export default UpdatePricingModal;