import {strApi} from "../../../app/strApi";
import {getFileName, serialize, showToast} from "../../../helpers/utils/Utils";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const usersApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchUsers: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/users/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['users'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        createUser: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/users`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['users'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "User")
            },
        }),
        getUserById: build.query({
            query: (id) => {
                return {
                    url: `/users/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['userById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateUser: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/users/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['users', 'userById', 'userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "User")
            },
        }),

        resetPasswordFromWeb: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/users/reset-password-web`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Reset", "password")
            },
        }),

        resetPasswordFromProfile: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/users/reset-password-from-profile`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Reset", "password")
            },
        }),

        resetPassword: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/users/reset-password`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Rested", "password")
            },
        }),

        forgotPassword: build.mutation({
            query: (emailId) => {
                return {
                    url: `/users/forgot-password?emailId=${emailId}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "", "A password reset email has been sent to the specified email address, if there is a user associated with it")
            },
        }),

        updateSimplifileToken: build.mutation({
            query: ({id, simplifileToken}) => {
                return {
                    url: `/users/${id}/simplifile-token`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: simplifileToken,
                };
            },
            invalidatesTags: ['fetchSimplifileTokenStatus'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Simplifile Token")
            },
        }),
        deleteSimplifileToken: build.mutation({
            query: (id) => {
                return {
                    url: `/users/${id}/simplifile-token`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['fetchSimplifileTokenStatus'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        fetchSimplifileTokenStatus: build.query({
            query: (id) => {
                return {
                    url: `/users/${id}/simplifile-token`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['fetchSimplifileTokenStatus'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getUserAuditableInfo: build.query({
            query: (requestBody) => {
                let paramData = serialize(requestBody);

                return {
                    url: `/users/convert-auditable-info?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        checkIsUserLeaderInTenant: build.query({
            query: (tenantId) => {
                return {
                    url: `/users/is-leader-by-tenant?tenantId=${tenantId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        checkIsUserMemberInTenant: build.query({
            query: (tenantId) => {
                return {
                    url: `/users/is-member-by-tenant?tenantId=${tenantId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        checkIsUserLeaderOfAnyTeam: build.query({
            query: () => {
                return {
                    url: `/users/is-leader`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        checkIsUserMemberOfAnyTeam: build.query({
            query: () => {
                return {
                    url: `/users/is-member`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        impersonateUser: build.mutation({
            query: (username) => {
                const paramData = serialize(username)
                return {
                    url: `/users/authenticate-impersonator?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled}) {
                queryFulfilled.then(res => {
                    localStorage.setItem("authToken", res.data.token)
                }).catch(error => {
                    showToast(error?.error?.data?.message, "error");
                })
            },
        }),

        exportUserData: build.query({
            query: (query) => {
                return {
                    url: `/users/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("users"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    })
})

export const {
    useSearchUsersQuery,
    useCreateUserMutation,
    useGetUserByIdQuery,
    useUpdateUserMutation,
    useResetPasswordMutation,
    useResetPasswordFromWebMutation,
    useImpersonateUserMutation,
    useUpdateSimplifileTokenMutation,
    useDeleteSimplifileTokenMutation,
    useFetchSimplifileTokenStatusQuery,
    useGetUserAuditableInfoQuery,
    useForgotPasswordMutation,
    useCheckIsUserLeaderInTenantQuery,
    useCheckIsUserLeaderOfAnyTeamQuery,
    useCheckIsUserMemberOfAnyTeamQuery,
    useCheckIsUserMemberInTenantQuery,
    useLazyExportUserDataQuery,
    useResetPasswordFromProfileMutation
} = usersApi;