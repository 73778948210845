import {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useLazyExportTeamDataQuery, useSearchTeamsQuery} from "./teamsApi";
import {useNavigate} from "react-router-dom";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {SearchSelectWithList} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {useGetAllTenantsQuery} from "../customer/tenantApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const Teams: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Teams", url: ""},

    ])

    useEffect(() => {
        document.title = "Teams";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Team");
    const tenantData = useGetAllTenantsQuery("")
    const [triggerExportTeamDataQuery] = useLazyExportTeamDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "teamName",
            cell: (props: { row: any; value: any }) => {
                let path = `/access-control/teams/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["TEAM-R", "TEAM-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'name'}
                    placeholder={'Enter Name...'}
                    name={'name'}/>
            }]
        },
        {
            label: "Tenant",
            accessor: "tenantName",
            visibility: {
                disallowedCustomerLevels: ["TENANT", "CUSTOMER"]
            },
            canSort: false,
            filterOptions: [{
                filterComponent: SearchSelectWithList(tenantData?.data, "name", "name", "Select Tenant...", false, "tenantId"),
            }]
        },
        {
            label: "Customer", accessor: "customerName", canSort: false,
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER"]
            }
        },
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchTeamsQuery,
                    exportAction: triggerExportTeamDataQuery
                }}
                userSettingGroup={"User"}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/access-control/teams/create")
                                }}
                                title={"Create Team"}
                            />,
                            permissions: ["TEAM-C"]
                        },
                }}
                basePermission={"TEAM"}
            ></DataTable>

        </div>

    )
}
export default Teams