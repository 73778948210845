import React, {FC, FormEvent, useState} from "react";
import Form from "../../../components/common/form/Form";
import ButtonTray from "../../../components/common/form/ButtonTray";
import Modal from "../../../components/common/form/Modal";
import SelectField from "../../../components/common/form/SelectField";
import InputField from "../../../components/common/form/InputField";
import {ModalProps} from "../../../helpers/constants";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {useCreateMfaDeviceMutation, useLazyGenerateQrCodeQuery} from "../mfaDeviceApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";

const CreateMFADeviceModal: FC<ModalProps> = ({show, setShow}) => {
    const [type, setType] = useState<{ label: string; value: string } | null>(null);
    const [credential, setCredential] = useState<string>("");
    const [preferred, setPreferred] = useState<boolean>(false);
    const [createMfaDevice, {isLoading: isCreating}] = useCreateMfaDeviceMutation();
    const [generateQrCodeQuery, generateQrCodeData] = useLazyGenerateQrCodeQuery();
    const {data: enums} = useGetEnumsQuery("");

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!type) return;

        await handleRTKQuery(
            async () => {
                return createMfaDevice({
                    type: type.value,
                    credential,
                    preferred,
                    userId: jwtDecode()?.sub,
                }).unwrap();
            },
            (response: any) => {
                setType(null);
                setCredential("");
                setPreferred(false);

                if (type.value === "AUTHENTICATOR") {
                    generateQrCodeQuery(response.id);
                } else {
                    setShow(false);
                }
            }
        );
    };

    return (
        <Modal
            title="Setup MFA Device"
            show={show}
            setShow={setShow}
            className="w-2/5 mx-4"
            fields={
                generateQrCodeData?.data ? (
                    <div>
                        <p className="text-text-1">Use your authenticator app to scan the QR code.</p>
                        <p className="text-text-1">
                            This will connect the authenticator app with your account, and is a required step.
                        </p>
                        <div className="flex justify-center items-center mb-5 mt-5">
                            {/*// @ts-ignore*/}
                            <img className="" src={generateQrCodeData?.data?.qcCode} alt="QR Code" />
                        </div>
                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Finish",
                                        onClick: () => setShow(false),
                                        type: "confirm",
                                    },
                                    buttonType: "button",
                                },
                            ]}
                            align="end"
                            gap={2}
                        />
                    </div>
                ) : (
                    <Form
                        onSubmit={handleSubmit}
                        fields={
                            <>
                                <SelectField
                                    placeholder="Select Type..."
                                    value={type || ""}
                                    options={createOptionListForSelectTag(enums?.["MfaType"], "displayName", "constant")}
                                    onChange={(selectedOption) => setType(selectedOption)}
                                    required
                                    label="Type"
                                />
                                {type?.value === "EMAIL" && (
                                    <InputField
                                        name="credential"
                                        label="Credential"
                                        value={credential}
                                        onChange={(e) => setCredential(e.target.value)}
                                        type="email"
                                        required
                                        placeholder="Enter Email Address..."
                                    />
                                )}
                                {type?.value === "SMS" && (
                                    <InputField
                                        name="credential"
                                        label="Credential"
                                        value={credential}
                                        onChange={(e) => setCredential(e.target.value)}
                                        type="number"
                                        required
                                        placeholder="Enter Phone No..."
                                    />
                                )}
                                <InputField
                                    name="preferred"
                                    label="Preferred"
                                    type="checkbox"
                                    checked={preferred}
                                    onChange={() => setPreferred(!preferred)}
                                />
                                <ButtonTray
                                    buttons={[
                                        {
                                            buttonProps: {
                                                btnText: "Cancel",
                                                onClick: () => setShow(false),
                                                type: "close",
                                            },
                                            buttonType: "button",
                                        },
                                        {
                                            buttonProps: {
                                                btnText: "Submit",
                                                type: "submit",
                                                isLoading: isCreating,
                                                isDisabled: false,
                                            },
                                            buttonType: "button",
                                        },
                                    ]}
                                    align="end"
                                    gap={2}
                                />
                            </>
                        }
                    />
                )
            }
        />
    );
};

export default CreateMFADeviceModal;
