import React, {FC, FormEvent, useState} from "react";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import {useGetOrderNoteListQuery, useUpdateNoteVisibilityMutation} from "../productsApi";
import {useDispatch} from "react-redux";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {strApi} from "../../../app/strApi";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import AuditableInfo from "../../../components/common/AuditableInfo";
import ModalButton from "../../../components/common/form/ModalButton";
import AddNoteModal from "../modal/AddNoteModal";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import {FaCirclePlus} from "react-icons/fa6";
import {skipToken} from "@reduxjs/toolkit/query";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";

interface props {
    productId?: string
}

const ProductNotes: FC<props> = ({productId}) => {

    const [selectedNoteId, setSelectedNoteId] = useState("");

    const {
        data: orderNotes,
        isLoading: loading,
        isError: error,
    } = useGetOrderNoteListQuery(productId || skipToken,{
        pollingInterval: 15000
    });

    const dispatch = useDispatch();
    const [updateNoteVisibility, {isLoading}] = useUpdateNoteVisibilityMutation()

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // @ts-ignore
        await handleRTKQuery(
            async () => {
                return await updateNoteVisibility({productId: productId, id: selectedNoteId}).unwrap();
            },
            () => {
                dispatch(strApi.util.invalidateTags(["orderNoteList"]))
                setSelectedNoteId("");
            }
        );
    }

    const iconButtonStyle = "cursor-pointer text-highlight-3 text-lg hover:text-highlight-7";

    return (
        <>
            <div className="bg-surface-2 rounded-md p-3">
                <div className="flex gap-2 items-center justify-between border-b border-surface-3 pb-2">
                    <h3 className="text-text-1 font-semibold">Notes ({orderNotes?.length || 0})</h3>
                    {hasPermission(["ONOT-C"]) &&
                        <ModalButton
                            triggerButton={<FaCirclePlus className={iconButtonStyle}
                                                         title={"Add Note"}/>}
                            modal={
                                <AddNoteModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    productId={productId}
                                />
                            }
                        />}

                </div>
                <div className='overflow-y-auto max-h-56'>
                    {
                        (loading) ?
                            <SkeletonLoader className={"mt-2"} count={3}></SkeletonLoader> :
                            !error && orderNotes?.length === 0 ?
                                <div className="mt-1 border border-surface-3 p-2 rounded-sm">
                                    <h3 className="text-text-1">
                                        No notes to display
                                    </h3>
                                </div>
                                :
                                !error && orderNotes?.map((data: any) => {
                                    return <div className="border border-surface-3 p-2 rounded-sm mt-3">
                                        <div className="grid-cols-12 lg:grid sm:block">
                                            <div className="col-span-6">
                                                <div className="flex gap-2">
                                                    <div className={"mt-1"}>
                                                        {hasPermission(["ONOT-U"]) &&
                                                            <ModalButton
                                                                triggerButton={data?.external ?
                                                                    <FaEye className={iconButtonStyle}/> :
                                                                    <FaEyeSlash
                                                                        className={iconButtonStyle}/>}
                                                                onOpen={() => {
                                                                    setSelectedNoteId(data?.id)
                                                                }}
                                                                modal={
                                                                    <ConfirmationModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        title={"Change Note Visibility"}
                                                                        bodyMessage={data?.external ? "Are you sure you want to hide this from customers?" : "Are you sure you want to show this to customers?"}
                                                                        onSubmit={handleSubmit}
                                                                        isLoading={isLoading}
                                                                    />
                                                                }
                                                            />
                                                        }
                                                    </div>
                                                    <h3 className="text-text-1">{data?.noteBody}</h3>
                                                </div>
                                            </div>
                                            <div className="col-span-6 text-sm">
                                                <AuditableInfo data={data}/>
                                            </div>
                                        </div>
                                    </div>
                                })

                    }
                </div>
            </div>

        </>
    )
}

export default ProductNotes;