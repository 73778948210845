import React, {FC, useEffect, useState} from "react";
import {DetailRow, Separator} from "../../../helpers/utils/OrderDetailUtils";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {useGetProductByIdQuery} from "../productsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetRecipientByIdQuery, useSubmitRecordingPackageFromSimplifileMutation} from "../simplifileApi";
import SimplifileLinkButton from "./SimplifileLinkButton";
import {AuthTokenPage} from "../AuthTokenPage";
import OrderDetailInfo from "./OrderDetailInfo";
import {
    useGetRecordingPackageByProductIdQuery,
    useUpdateRecordingPackageFromSimplifileMutation
} from "../recordingPackageApi";
import {FaBoxesPacking, FaRotateRight} from "react-icons/fa6";
import IconButton from "../../../components/common/form/IconButton";
import {showToast} from "../../../helpers/utils/Utils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {useGetEnumsQuery} from "../../../app/globalApi";

interface Props {
    orderQuery: any,
    productId?: string | null;
}

const RecordingProductDetailInfo: FC<Props> = ({productId, orderQuery}) => {
    const {data: enums} = useGetEnumsQuery("");

    const {
        data: productData,
        isLoading: isProductLoading,
        isError: isProductError,
    } = useGetProductByIdQuery(productId, {skip: !productId, pollingInterval: 15000});

    const {
        data: recordingPackageData,
        isLoading: isRecordingPackageLoading,
        isError: isRecordingPackageError,
    } = useGetRecordingPackageByProductIdQuery(productId, {skip: !productId, pollingInterval: 5000});

    const {
        data: recipientData,
        isLoading: isRecipientLoading,
        isError: isRecipientError,
    } = useGetRecipientByIdQuery({
        id: recordingPackageData?.vendorDetails?.vendorRecipientId,
        query: {tenantId: recordingPackageData?.tenantId}
    }, {skip: !recordingPackageData?.vendorDetails?.vendorRecipientId})

    const [updatePackage, {
        isLoading: isPackageLoading,
        isSuccess: isPackageSuccess
    }] = useUpdateRecordingPackageFromSimplifileMutation();

    const [submitPackage, {
        isLoading: isPackageSubmitLoading,
        isSuccess: isPackageSubmitSuccess
    }] = useSubmitRecordingPackageFromSimplifileMutation();

    const vendorLastTransactionDate = recordingPackageData?.vendorDetails?.vendorTransactionInfo?.vendorLastTransactionDate;
    const formattedVendorLastTransactionDate = formatValueAsDate(vendorLastTransactionDate);

    useEffect(() => {
        if (!isPackageSuccess) return;

        showToast('Updated package from Simplifile!', 'success');
    }, [isPackageSuccess]);

    useEffect(() => {
        if (!isPackageSubmitSuccess) return;

        showToast('Submit package to Simplifile!', 'success');
    }, [isPackageSubmitSuccess]);

    const PackageButtonTray = () => {
        const handleUpdatePackage = () => {
            updatePackage(recordingPackageData?.id);
        };

        const handleSubmitPackage = () => {
            submitPackage({recordingPackageId : recordingPackageData?.id,tenantId:recordingPackageData?.tenantId
        });
        };

        return <div className="flex gap-2 items-center justify-between">
            {/*Empty div + justify-between makes our other div float right*/}
            <div/>
            <div className='flex gap-2'>
                {recordingPackageData &&
                    <>
                        {recordingPackageData?.vendorDetails?.vendorTransactionInfo ?
                            <div className='inline-flex gap-4'>
                                <IconButton icon={<FaRotateRight/>}
                                            onClick={handleUpdatePackage}
                                            title='Update package from SimpliFile'
                                            isLoading={isPackageLoading}
                                            isDisabled={isPackageLoading}/>

                                <SimplifileLinkButton
                                    page={AuthTokenPage.VIEW_PACKAGE}
                                    objectId={recordingPackageData?.vendorDetails?.vendorTransactionInfo?.vendorObjectId}
                                    className={'inline-flex items-center'}
                                    tenantId={productData?.tenantId}/>
                            </div>
                            :
                            <div>
                                <IconButton
                                    icon={<FaBoxesPacking/>}
                                    label='Send Package'
                                    onClick={handleSubmitPackage}
                                    title='Send package to SimpliFile'
                                    isLoading={isPackageSubmitLoading}
                                    isDisabled={isPackageSubmitLoading}
                                />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    }


    const instruments = [...new Set(recordingPackageData?.documents?.map((doc: any) => doc.type as string))]
        ?.filter(Boolean)
        ?.join(', ');


    // TODO: Show invoice total, but hardcode an additional 30

    return (
        <div className="bg-surface-2 rounded-md rounded-tl-none p-3 pt-1.5">
            <OrderDetailInfo orderQuery={orderQuery}/>
            <DetailRow label="Status"
                       value={getEnumDisplayNameByConstant(enums?.["ProductStatus"], productData?.status)}
                       isLoading={isProductLoading}
                       isError={isProductError}
                       isValueSpan={true}/>

            <DetailRow
                label="Created"
                value={productData && formatValueAsDate(productData?.creationDate)}
                isLoading={isProductLoading}
                isError={isProductError}/>

            <DetailRow
                label="Created by"
                value={productData && productData.createdBy}
                isLoading={isProductLoading}
                isError={isProductError}/>

            <DetailRow label="Needed by"
                       value={formatValueAsDate(productData?.needByDate) || "-"}
                       isLoading={isProductLoading} isError={isProductError}/>

            <DetailRow
                label="Assignee"
                value={productData && productData?.assigneeName}
                isLoading={isProductLoading}
                isError={isProductError}/>

            <PackageButtonTray/>

            <Separator/>

            <DetailRow
                label="Vendor Status"
                isValueSpan={recordingPackageData?.vendorDetails?.vendorStatus}
                value={recordingPackageData?.vendorDetails?.vendorStatus || 'Unsent'}
                isLoading={isRecordingPackageLoading}
                isError={isRecordingPackageError}
            />

            <DetailRow
                label="Last updated"
                value={recordingPackageData && formattedVendorLastTransactionDate}
                isLoading={isRecordingPackageLoading}
                isError={isRecordingPackageError}/>

            <DetailRow label="Recipient"
                       value={recipientData?.name ? `${recipientData?.name} (${recipientData?.recipientID})` : '-'}
                       isLoading={isRecipientLoading}
                       isError={isRecipientError}/>

            <DetailRow label="Created by" value={productData?.createdBy}
                       isLoading={isProductLoading}
                       isError={isProductError}/>

            <DetailRow label="Instruments"
                       value={instruments}
                       isLoading={isRecordingPackageLoading}
                       isError={isRecordingPackageError}
                       className='text-wrap'/>
        </div>
    );
};

export default RecordingProductDetailInfo;
