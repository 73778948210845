import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../helpers/utils/Utils";

export const mfaDeviceApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        createMfaDevice: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/mfa-devices`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['mfaDevices'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Mfa Device")
            },
        }),

        deleteMfaDeviceById: build.mutation({
            query: (id) => {
                return {
                    url: `/mfa-devices/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['mfaDevices'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Mfa Device");
            },
        }),


        verifyMfaDeviceChallenge: build.mutation({
            query: ({id, challengeId, solution}) => {
                const paramData = serialize({challengeId: challengeId, solution: solution});
                return {
                    url: `/mfa-devices/${id}/verify?${paramData}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['mfaDevices'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Verified", "Mfa Device");
            },
        }),

        issueVerificationToken: build.mutation({
            query: (id) => {
                return {
                    url: `/mfa-devices/${id}/verify`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['mfaDevices'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),

        preferMfaDeviceById: build.mutation({
            query: (id) => {
                return {
                    url: `/mfa-devices/${id}/prefer`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ['mfaDevices'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Preferred", "Mfa Device");
            },
        }),

        getUserMfaDevice: build.query({
            query: () => {

                return {
                    url: `/mfa-devices`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["mfaDevices"]
        }),

        generateQrCode: build.query({
            query: (id) => {
                return {
                    url: `/mfa-devices/${id}/qr-code`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            transformResponse: response => {
                return response;
            },
        }),
    })
});

export const {
    useCreateMfaDeviceMutation,
    useDeleteMfaDeviceByIdMutation,
    useVerifyMfaDeviceChallengeMutation,
    usePreferMfaDeviceByIdMutation,
    useGetUserMfaDeviceQuery,
    useIssueVerificationTokenMutation,
    useLazyGenerateQrCodeQuery
} = mfaDeviceApi