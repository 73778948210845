import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";

export const recordingPackageApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getRecordingPackageByProductId: build.query({
            query: (productId) => {
                return {
                    url: `/products/${productId}/recording-package`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['recordingPackageByProductId'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateRecordingPackageFromSimplifile: build.mutation({
            query: (recordingPackageId: string) => {
                return {
                    url: `/recording-packages/${recordingPackageId}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['recordingPackageByProductId'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Recording Package")
            },
        }),

    })
})

export const {
    useGetRecordingPackageByProductIdQuery,
    useUpdateRecordingPackageFromSimplifileMutation
} = recordingPackageApi