import {toast, TypeOptions} from "react-toastify";
import {useEffect, useRef} from "react";

export const isNullOrEmpty = (data: any): boolean => {
    return (
        data === null ||
        data === undefined ||
        data === '' ||
        (Array.isArray(data) && data.length === 0) ||
        (typeof data === 'object' && Object?.keys(data).length === 0)
    );
}

export const showToast = (message = "", type: TypeOptions = "info") => {
    if (isNullOrEmpty(message) || isNullOrEmpty(type) || !type) {
        return;
    }

    if(!toast.isActive(message)){
        toast(message, {type: type, toastId: message});
    }
};

export function getFirstNonNull(values: any[]): any {
    return values.find(v => v != null);
}

export function serialize(obj: any) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p].join(',')));
            } else {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    return str.join("&");
}

export const createOptionListForSelectTag = (data: any[] | null = null, label = "", value = "", suffix = "", overrideLabel = "") => {
    let list: any[] = [];

    if (isNullOrEmpty(data) || isNullOrEmpty(label) || isNullOrEmpty(value)) {
        return list;
    }

    if (!Array.isArray(data)) {
        return list;
    }

    list = data?.map(cur => {


        const labelText =
            (isNullOrEmpty(overrideLabel) || isNullOrEmpty(cur[overrideLabel]))
                ? cur[label] // Fallback to the label if overrideLabel is empty or not found
                : cur[overrideLabel]; // Use overrideLabel if it's valid


        return {
            value: cur[value],
            label: labelText + (isNullOrEmpty(suffix) ? "" : " (" + cur[suffix] + ")"),
        }
    })

    return list;
}

export const filterObjectFromArray = (array: any[], keyToCompare: string | number, value: string | number) => {
    if (isNullOrEmpty(array)) {
        return null
    }
    const filteredItem = array.find((item) => item[keyToCompare] === value);
    return filteredItem ?? null;
}

export const createOptionTypeListFromArray = (array: string[]) => {
    if (isNullOrEmpty(array)) {
        return []
    }
    return array.map(cur => ({label: cur, value: cur}))
}

export const removeItemAtIndex = (array: any[], index: number) => {
    if (index < 0 || index >= array.length) {
        return array;
    }
    return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const getBooleanDisplayIcon = (value = "", trueTitle = "", falseTitle = "", className = "ml-4") => {
    return (
        <>
            {value !== "" ?
                <div>
                    <i title={value ? trueTitle : falseTitle}
                       className={value ? `fa fa-check-circle text-highlight-7 ${className}` : `fa fa-times-circle text-error-2 ${className}`}
                    />
                </div> :
                <></>
            }
        </>
    )
}

export let booleanChecks = [{value: false, label: "Disabled"}, {value: true, label: "Enabled"}]


//validatePassword
export interface PasswordValidationErrors {
    uppercaseChar?: string;
    lowercaseChar?: string;
    number?: string;
    specialChar?: string;
    length?: string;
    match?: string;
    isValidSubmission: boolean;
    uppercaseCheck?: string;
    lowercaseCheck?: string;
    numberCheck?: string;
    specialCharCheck?: string;
    lengthCheck?: string;
}

export const validatePassword = (password: string, confirmPassword: string): PasswordValidationErrors => {
    const regexForUppercase = new RegExp("(?=.*[A-Z])");
    const regexForLowercase = new RegExp("(?=.*[a-z])");
    const regexForNum = new RegExp(/\d/);
    const regexForSpecialChar = new RegExp("(?=.*\\W)");
    const regexForLength = new RegExp(/^.{12,50}$/);

    let error: PasswordValidationErrors = {
        isValidSubmission: false,
    };

    if (!regexForUppercase.test(password)) {
        error.uppercaseChar = "Must contain at least one uppercase letter (A - Z)";
    } else {
        error.uppercaseCheck = "true";
    }

    if (!regexForLowercase.test(password)) {
        error.lowercaseChar = "Must contain at least one lowercase letter (a - z)";
    } else {
        error.lowercaseCheck = "true";
    }

    if (!regexForNum.test(password)) {
        error.number = "Must contain at least one number (0 - 9)";
    } else {
        error.numberCheck = "true";
    }

    if (!regexForSpecialChar.test(password)) {
        error.specialChar = "Must contain at least one special character (e.g. !, #, $, %)";
    } else {
        error.specialCharCheck = "true";
    }

    if (!regexForLength.test(password)) {
        error.length = "Must contain between 12 and 50 characters";
    } else {
        error.lengthCheck = "true";
    }

    if (password !== confirmPassword && confirmPassword !== null && confirmPassword !== "") {
        error.match = "Password and Confirm Password don't match!";
    } else {
        error.match = "";
    }

    error.isValidSubmission =
        regexForUppercase.test(password) &&
        regexForLowercase.test(password) &&
        regexForNum.test(password) &&
        regexForSpecialChar.test(password) &&
        regexForLength.test(password);

    if (error.isValidSubmission) {
        delete error.uppercaseChar;
        delete error.lowercaseChar;
        delete error.number;
        delete error.specialChar;
        delete error.length;
    }

    return error;
};

export const reorderProperties = (obj: any) => {
    return {
        type: obj.type?.value,
        firstName: obj.firstName,
        middleName: obj.middleName,
        lastName: obj.lastName,
        nameUnparsed: obj.nameUnparsed,
    };
};


export const getFileName = (entity: string, extension = '.csv') => {
    let instance = new Date();
    return entity + "_" + (instance.getMonth() + 1) + "-" + instance.getDate() + "-" + instance.getFullYear() + "_" + instance.getTime() + extension;
}

export function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
