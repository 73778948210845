import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {isNullOrEmpty} from "../../../helpers/utils/Utils";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import InputField from "../../../components/common/form/InputField";
import Modal from "../../../components/common/form/Modal";
import Button from "../../../components/common/form/Button";
import {
    useSearchCannedNotesQuery,
} from "../../data/cannedNotes/cannedNotesApi";
import {useCreateNoteMutation} from "../productsApi";
import {DataTable} from "../../../components/common/datatable/DataTable";

interface AddNoteProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    productId: any
}

const AddNoteModal: FC<AddNoteProps> = ({show, setShow, productId}) => {
    const [tab, setTab] = useState(1);
    const [createNote, createNoteApiResult] = useCreateNoteMutation();

    const [selectedNote, setSelectedNote] = useState<any>(null);
    const [noteData, setNoteData] = useState({
        external: false,
        noteBody: "",
        saveAsCanned: false,
        bold: false,
        redColor: false,
        yellowHighlight: false
    });

    const columns = [
        {
            label: "Note No.",
            accessor: "noteNumber",
            cell: (props: any) => (
                <span id={props.row.original.id}>
                    {props.value}
                </span>
            ),
        },
        {
            label: "Message",
            accessor: "comment",
            cell: (props: any) => (
                <span className={formatCell(props.row.original)}>
                    {props.value}
                </span>
            ),
        },
        {
            label: "Type",
            accessor: "type",
        },
    ];

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        let {name, value} = e.target
        setNoteData({
            ...noteData,
            //@ts-ignore
            [name]: ["external", "saveAsCanned", "bold", "redColor", "yellowHighlight"].includes(name) ? !noteData[name] : value
        })
    };


    const handleCreateNote = async () => {
        if (productId) {
            await handleRTKQuery(() => {
                createNote({
                    productId,
                    requestBody: {
                        external: noteData.external,
                        noteBody: tab === 1 ? selectedNote?.original.comment : noteData.noteBody,
                        noteType: "ORDER"
                    },
                    param: {
                        saveAsCanned: noteData.saveAsCanned,
                        bold: noteData.bold,
                        redColor: noteData.redColor,
                        yellowHighlight: noteData.yellowHighlight
                    }
                });
            }, () => {
                setShow(false);
            });
        }

    };

    const handleRowSelection = (newSelectedRow: any, row: any) => {
        setSelectedNote(row);
    };

    const formatCell = (row: any) => {
        let classNames = "";
        if (row.bold) classNames += " font-extra bold";
        if (row.redColor) classNames += " text-error-2";
        return classNames;
    };

    const getRowStyle = (row: any) => {
        let classNames = "cursor-pointer";
        if (row.original.yellowHighlight) classNames += " bg-warn-2 text-black";
        return classNames;
    };

    useEffect(() => {
        if (selectedNote) {
            setNoteData({
                ...noteData,
                external: false,
                noteBody: selectedNote.original.comment,
                saveAsCanned: false,
                bold: false,
                redColor: false,
                yellowHighlight: false,
            });
        }
    }, [selectedNote]);

    return (
        <Modal title={"Add Note"} show={show} setShow={setShow} className="mx-4" fields={
            <>
                <div className="flex border-b border-surface-6">
                    <button
                        className={`text-text-1 px-5 py-3 rounded-tl-md rounded-tr-md ${tab === 1 ? "bg-highlight-2" : ""}`}
                        onClick={() => {
                            !createNoteApiResult.isLoading && setTab(1);
                        }}>Canned
                    </button>
                    <button
                        className={`px-4 py-3 text-text-1 rounded-tl-md rounded-tr-md ${tab === 2 ? "bg-highlight-2" : ""}`}
                        onClick={() => {
                            !createNoteApiResult.isLoading && setTab(2);
                        }}>Custom
                    </button>
                </div>
                {tab === 1 && (
                    <DataTable
                        columns={columns}
                        defaultFilter={{pageSize: 5}}
                        defaultSortOptions={{accessor: 'creationDate', direction: 'desc'}}
                        userSettingGroup={"CannedNote"}
                        appendFilterToUrl={false}
                        actions={{fetchAction: useSearchCannedNotesQuery}}
                        rowSelectionOptions={{
                            enableRowSelection: true,
                            onSelectionChange: handleRowSelection,
                            enableMultiRowSelection: false,
                            rowIdAccessor: 'id'
                        }}
                    />
                )}
                {tab === 2 && (
                    <div className="grid grid-cols-12 mt-4 gap-5">
                        <div className="col-span-6">
                            <InputField
                                labelPosition={"right"}
                                label="Internal"
                                name={"external"}
                                onChange={handleOnChange}
                                type="radio"
                                checked={!noteData.external}
                            />
                        </div>
                        <div className="col-span-6">
                            <InputField
                                labelPosition={"right"}
                                label="External"
                                name={"external"}
                                onChange={handleOnChange}
                                type="radio"
                                checked={noteData.external}
                            />
                        </div>
                        <div className="col-span-3 mt-4">
                            <label className="text-text-1 ps-1">*Comment</label>
                        </div>
                        <div className="col-span-9 mt-4">
                            <textarea
                                name={"noteBody"}
                                value={noteData.noteBody}
                                //@ts-ignore
                                onChange={handleOnChange}
                                className="w-full bg-surface-2 rounded-md text-text-1 form_control border border-surface-3 p-2"
                                placeholder="Enter Note Body...."
                            />
                        </div>
                        <InputField
                            label={"Saved As Canned"}
                            type={"checkbox"}
                            checked={noteData.saveAsCanned}
                            name={"saveAsCanned"}
                            onChange={handleOnChange}
                        />
                        {noteData.saveAsCanned && (
                            <>
                                <InputField
                                    label={"Bold"}
                                    type={"checkbox"}
                                    checked={noteData.bold}
                                    name={"bold"}
                                    onChange={handleOnChange}
                                />
                                <InputField
                                    label={"Red Color"}
                                    type={"checkbox"}
                                    checked={noteData.redColor}
                                    name={"redColor"}
                                    onChange={handleOnChange}
                                />
                                <InputField
                                    label={"Yellow Highlight"}
                                    type={"checkbox"}
                                    checked={noteData.yellowHighlight}
                                    name={"yellowHighlight"}
                                    onChange={handleOnChange}
                                />
                            </>
                        )}
                    </div>
                )}
                <div className="flex justify-end gap-2 mt-3 pt-4 border-t border-surface-3">
                    <Button
                        className={"text-sm font-medium text-black focus:outline-none bg-white rounded-lg border border-gray-200 focus:z-10 focus:ring-4 focus:ring-gray-100"}
                        onClick={() => setShow(false)} type={"close"} btnText={"Cancel"}
                    />
                    <Button
                        isLoading={createNoteApiResult.isLoading}
                        isDisabled={isNullOrEmpty(noteData.noteBody)}
                        className={"focus:outline-none font-medium rounded-lg text-sm inline-flex items-center text-center"}
                        onClick={handleCreateNote} type={"submit"} btnText={"Add Note"}
                    />
                </div>
            </>
        }/>
    );
};

export default AddNoteModal;
