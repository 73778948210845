export const jwtDecode = () => {
    try {
        const token = localStorage.getItem("authToken")
        // @ts-ignore
        const arrayToken = token.split('.');
        return JSON.parse(atob(arrayToken[1]));
    } catch (error) {
        // If Parsing token results in error means token is malformed and user must return to login screen
        window.location.href = '/login';
    }
}

export const isTokenExpired = () => {
    return jwtDecode()?.exp < (Date.now() / 1000);
}

export const getExpandedAuthorities = (authorities = []) => {
    if (authorities.length === 0) {
        return []
    }
    let expandedAuthorities: any[] = [];
    authorities?.forEach(cur => {
        // @ts-ignore
        const [permissionSuffix, permissionPrefix] = cur.split("-")
        if (permissionPrefix.length > 1) {
            // @ts-ignore
            const permission = permissionPrefix.split("")?.map(per => `${permissionSuffix}-${per}` )
            expandedAuthorities.push(...permission)
        } else {
            expandedAuthorities.push(cur)
        }
    })
    return expandedAuthorities;
}
export const hasPermission = (permissions : string[], isMatchAll = false) => {
    const grantedAuthorities = getExpandedAuthorities(jwtDecode()?.grantedAuthorities)
    return isMatchAll ?
        permissions.every(permission => grantedAuthorities.includes(permission)) :
        permissions.some(userPermission => grantedAuthorities.includes(userPermission))
}