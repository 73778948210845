import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader, getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const tenantPricingApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchTenantPricing: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/tenant-pricings/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['tenantPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getTenantPricingById: build.query({
            query: (regionId) => {
                return {
                    url: `/tenant-pricings/${regionId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createTenantPricing: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/tenant-pricings`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['tenantPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Tenant Pricing");
                await handleQueryError(queryFulfilled)
            },
        }),

        updateTenantPricing: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/tenant-pricings/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['tenantPricing', 'tenantPricingById','userAuditableInfo'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Tenant Pricing");
            },
        }),

        deleteTenantPricingById: build.mutation({
            query: (id) => {
                return {
                    url: `/tenant-pricings/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['tenantPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Tenant Pricing");
            },
        }),

        exportTenantPricingData: build.query({
            query: (query) => {
                return {
                    url: `/tenant-pricings/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("tenantPricing"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
})

export const {
    useSearchTenantPricingQuery,
    useUpdateTenantPricingMutation,
    useCreateTenantPricingMutation,
    useDeleteTenantPricingByIdMutation,
    useGetTenantPricingByIdQuery,
    useLazyExportTenantPricingDataQuery
} = tenantPricingApi