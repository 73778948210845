import {serialize, showToast} from "../../helpers/utils/Utils";
import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../helpers/utils/RtkQueryUtils";

const loginPageApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        loginUser: build.mutation({
            query: (request) => ({
                url: "/authenticate",
                method: "POST",
                body: request,
            }),
            async onQueryStarted(_, {queryFulfilled}) {
                queryFulfilled.then(res => {
                    // TODO: This should be in the Redux store, along with roles, username, customerLevel, etc
                    localStorage.setItem("authToken", res.data.token)
                }).catch(error => {
                    showToast(error?.error?.data?.message, "error");
                })
            }
        }),

        requestMfaChallenge: build.mutation({
            query: ({challengeId, mfaDeviceId, remember}) => {
                const paramData = serialize({challengeId: challengeId, mfaDeviceId: mfaDeviceId, remember: remember});
                return {
                    url: `/authenticate/mfa/challenge?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),

        verifyMfaChallenge: build.mutation({
            query: ({challengeId, solution}) => {
                const paramData = serialize({challengeId: challengeId, solution: solution});
                return {
                    url: `/authenticate/mfa/verify?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled);
            },
        }),

    })
})
export const {useLoginUserMutation,
    useRequestMfaChallengeMutation,
    useVerifyMfaChallengeMutation,
} = loginPageApi;