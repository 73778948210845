import React, {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useFindAllRolesQuery} from "./rolesApi";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";


const Roles: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Roles", url: ""},

    ])

    useEffect(() => {
        document.title = "Roles";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Role");

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "name",
            canSort: false,
            cell: (props: any) => {
                let path = `/access-control/roles/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["ROL-R", "ROL-V"]);
            }
        },
        {
            label: "Authority", accessor: "authorityLevel",
            canSort: false
        },
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useFindAllRolesQuery
                }}
                buttonOptions={{
                    addButton: {
                        button: <IconButton
                            icon={<FaPlus/>}
                            hasBackground={true}
                            onClick={() => {
                                navigate("/access-control/roles/create")
                            }}
                            className={"h-7"}
                            title={"Create Role"}
                        />,
                        permissions: ["ROL-C"]
                    },
                }}
                userSettingGroup={"Role"}
            ></DataTable>

        </div>

    )
}
export default Roles