import moment from "moment";
import {isNullOrEmpty} from "./Utils";

// Format a string as a moment date using given dateFormat
export const formatValueAsDate = (dateValue:string, dateFormat = "M/D/YY hh:mm a") => {
    if (isNullOrEmpty(dateValue)) {
        return null;
    }

    return moment(dateValue).format(dateFormat).toUpperCase();
}