import React, {ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState} from "react";
import {
    useGetCustomerByIdQuery,
    useGetParentCustomerByTenantQuery,
    useUpdateCustomerMutation,
} from "../customerApi";
import Form from "../../../../components/common/form/Form";
import InputField from "../../../../components/common/form/InputField";
import {OptionType} from "../../../../helpers/utils/StyleUtils";
import SelectField from "../../../../components/common/form/SelectField";
import {createOptionListForSelectTag} from "../../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import {useNavigate, useParams} from "react-router-dom";
import {getEnumDisplayNameByConstant} from "../../../../helpers/utils/EnumUtils";
import {useRegionByCustomerIdQuery} from "../regionApi";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import ModalButton from "../../../../components/common/form/ModalButton";
import {
    useUpdatePdfSettingsMutation,
    useGetPdfSettingsByCustomerIdQuery,
    useGetPdfImageByNameQuery,
    useLazyViewPdfImageQuery
} from "../pdfSettingsApi";
import BranchTab from "../branch/BranchTab";
import RegionTab from "../region/RegionTab";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import {Tabs} from "react-simple-tabs-component";
import {Routes as AppRoutes} from "../../../../routes";
import ButtonTray from "../../../../components/common/form/ButtonTray";

const SettingTab = () => {

    let {customerId} = useParams();
    const navigate = useNavigate();

    const customerQuery = useGetCustomerByIdQuery(customerId ?? skipToken);
    const pdfSettingQuery = useGetPdfSettingsByCustomerIdQuery(customerId ?? skipToken);
    const parentCustomerByTenantQuery = useGetParentCustomerByTenantQuery(customerQuery?.data?.tenantId ?? skipToken);
    const [updateCustomer, updateCustomerApiResponse] = useUpdateCustomerMutation();
    const [updatePdfSettings, updatePdfSettingsApiResponse] = useUpdatePdfSettingsMutation();
    const pdfImageByNameIdQuery = useGetPdfImageByNameQuery(pdfSettingQuery?.data?.pdfLogoPath ?? skipToken);
    const [viewPdfLogo] = useLazyViewPdfImageQuery();
    const {data: enums} = useGetEnumsQuery("");

    const [customerData, setCustomerData] = useState<any>({});
    const [pdfSettingData, setPdfSettingData] = useState<any>({});
    const [render, setRender] = useState<any>("");
    const fileInputRef = useRef<any>(null);
    const [file, setFile] = useState<any>(null);

    const regionByCustomerIdQuery = useRegionByCustomerIdQuery(customerData?.parentCustomerReference?.value ?? skipToken)

    useEffect(() => {
        let customerObj = customerQuery?.data;
        if (customerObj) {
            setCustomerData({
                ...customerObj,
                contactStrategy: customerObj.contactStrategy ? {
                    value: customerObj.contactStrategy,
                    label: getEnumDisplayNameByConstant(enums?.["ContactStrategy"], customerObj.contactStrategy)
                } : null,
                customerType: customerObj.customerType ? {
                    value: customerObj.customerType,
                    label: getEnumDisplayNameByConstant(enums?.["CustomerType"], customerObj.customerType)
                } : null,
                billingType: customerObj.billingType ? {
                    value: customerObj.billingType,
                    label: getEnumDisplayNameByConstant(enums?.["BillingType"], customerObj.billingType)
                } : null,
                region: customerObj?.region ? {
                    value: customerObj?.region?.id,
                    label: customerObj?.region?.name
                } : null,
                parentCustomerReference: customerObj?.parentCustomerReference ? {
                    value: customerObj.parentCustomerReference?.id,
                    label: customerObj.parentCustomerReference?.customerName
                } : null,
            })
        }
    }, [customerQuery?.data]);

    useEffect(() => {
        let settingObj = pdfSettingQuery?.data;

        if (pdfSettingQuery?.data) {
            setPdfSettingData(
                {
                    ...settingObj,
                    pdfHeaderOption: settingObj.pdfHeaderOption ? {
                        value: settingObj.pdfHeaderOption,
                        label: getEnumDisplayNameByConstant(enums?.["PdfHeaderOption"], settingObj.pdfHeaderOption)
                    } : null,
                }
            )
        }
    }, [pdfSettingQuery?.data])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let obj = {
            ...customerData,
            customerType: customerData?.customerType?.value,
            billingType: customerData?.billingType?.value,
            contactStrategy: customerData?.contactStrategy?.value,
            region: customerData?.region?.value
                ? regionByCustomerIdQuery?.data?.find((el: any) => el.id === customerData?.region?.value)
                : null,
            parentCustomerReference: customerData?.parentCustomerReference?.value
                ? parentCustomerByTenantQuery?.data?.find((el: any) => el.id === customerData?.parentCustomerReference?.value)
                : null,
        };

        let pdfSetting = {
            ...pdfSettingData,
            id: pdfSettingQuery.data?.id,
            pdfHeaderOption: pdfSettingData.pdfHeaderOption?.value,
            pdfLogoPath: pdfSettingData.pdfLogoPath,
        };

        await handleRTKQuery(async () => {
            await updateCustomer({id: customerId, requestBody: obj}).unwrap();
            await updatePdfSettings({customerId: customerId, pdfLogo: file, pdfSettingObj: pdfSetting}).unwrap();
        });
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;

        setCustomerData((prevData: any) => ({
            ...prevData,
            [name]: value,
            // Reset region when parentCustomerReference changes
            ...(name === 'parentCustomerReference' && {region: null}),
        }));
    };


    const handlePdfSettingOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: OptionType | null
    }) => {
        const {name, value} = "target" in e ? e.target : e;
        setPdfSettingData({
            ...pdfSettingData,
            [name]: value,
        })
    }

    const handleToggle = (property: string) => () => {
        setCustomerData((prevData: any) => ({
            ...prevData,
            [property]: !prevData[property]
        }));
        setRender(new Date().getMilliseconds());
    };

    const handlePdfSettingToggle = (property: string) => () => {
        setPdfSettingData((prevData: any) => ({
            ...prevData,
            [property]: !prevData[property]
        }));
        setRender(new Date().getMilliseconds());
    };

    const selectedFile = (e: ChangeEvent<HTMLInputElement>) => {
        setFile(e.target.files?.[0]);
    }

    const handleViewDownloadFile = async (fileId: string) => {
        viewPdfLogo(fileId)
    }

    // Compute bottomTabs using useMemo
    const bottomTabs = useMemo(() => {
        const result: any[] = [];
        if (hasPermission(["BRAN-V"])) {
            result.push({
                label: 'Branches',
                Component: BranchTab
            });
        }
        if (customerData?.customerLevel === "CUSTOMER" && customerData.customerType.value === "WHOLESALE" && customerData.parentCustomer && hasPermission(["CUSRGN-V"])) {
            result.push({
                label: 'Regions',
                Component: RegionTab
            });
        }
        return result;
    }, [customerData.parentCustomer, customerData?.customerLevel]);


    return (
        <>
            <Form onSubmit={handleSubmit} fields={
                <div className={"bg-surface-2 p-5 rounded-b-md rounded-sm sm:w-full"}>
                    <div className="grid grid-cols-12">
                        <InputField
                            name={"customerName"}
                            label="Customer Name"
                            onChange={handleOnChange}
                            value={customerData?.customerName || ""}
                            type="text"
                            placeholder="Enter Customer Name..."
                            required={true}
                        />

                        <InputField
                            name={"customerNumber"}
                            label="Customer Number"
                            value={customerData?.customerNumber || ""}
                            type="text"
                            placeholder="Enter Customer Number..."
                            disabled={true}
                        />

                        <InputField
                            name={"customerLevel"}
                            label="Customer Level"
                            value={customerData?.customerLevel || ""}
                            type="text"
                            placeholder="Enter Customer Level..."
                            disabled={true}
                        />

                        <SelectField
                            placeholder='Select Contact Strategy...'
                            value={customerData?.contactStrategy || null}
                            options={createOptionListForSelectTag(enums?.["ContactStrategy"], "displayName", "constant")}
                            onChange={(selectedOption) => {
                                handleOnChange({name: "contactStrategy", value: selectedOption})
                            }}
                            required={true}
                            label={"Contact Strategy"}
                        />

                        <SelectField
                            placeholder='Select Customer Type...'
                            value={customerData?.customerType || null}
                            options={createOptionListForSelectTag(enums?.["CustomerType"], "displayName", "constant")}
                            onChange={(selectedOption) => {
                                handleOnChange({name: "customerType", value: selectedOption})
                            }}
                            required={true}
                            label={"Customer Type"}
                        />

                        <SelectField
                            placeholder='Select Billing Type...'
                            value={customerData?.billingType || null}
                            options={createOptionListForSelectTag(enums?.["BillingType"], "displayName", "constant")}
                            onChange={(selectedOption) => {
                                handleOnChange({name: "billingType", value: selectedOption})
                            }}
                            required={true}
                            label={"Billing Type"}
                        />

                        {customerData?.customerLevel === "CUSTOMER" && customerData?.customerType?.value === "WHOLESALE" &&
                            <InputField
                                name='parentCustomer'
                                label='Is Parent Customer'
                                onClick={handleToggle('parentCustomer')}
                                checked={!!customerData?.parentCustomer}
                                type='toggle'
                            />
                        }

                        {customerData?.customerType?.value === "RETAIL" &&
                            <>
                                <SelectField
                                    placeholder='Select Parent Customer...'
                                    value={customerData?.parentCustomerReference || null}
                                    options={createOptionListForSelectTag(parentCustomerByTenantQuery?.data?.filter((el: any) => el.id !== customerId), "customerName", "id", "customerNumber")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "parentCustomerReference", value: selectedOption})
                                    }}
                                    label={"Parent Customer"}
                                />
                                <SelectField
                                    placeholder='Select Parent Customer Region...'
                                    value={customerData?.region || null}
                                    options={createOptionListForSelectTag(regionByCustomerIdQuery?.data, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "region", value: selectedOption})
                                    }}
                                    label={"Parent Customer Region"}
                                />
                            </>
                        }


                        {customerData?.parentCustomerReference && customerData?.customerType?.value === "RETAIL" &&
                            <InputField
                                name={"useParentPdfSettings"}
                                label="Inherit parent PDF settings"
                                onClick={handleToggle("useParentPdfSettings")}
                                checked={customerData?.useParentPdfSettings || ""}
                                type="toggle"
                            />
                        }

                        <InputField
                            name={"requireMfa"}
                            label="Require MFA"
                            onClick={handleToggle("requireMfa")}
                            checked={customerData?.requireMfa || ""}
                            type="toggle"
                        />

                        <InputField
                            name={"enabled"}
                            label="Enable Customer"
                            onClick={handleToggle("enabled")}
                            checked={customerData?.enabled || ""}
                            type="toggle"
                        />


                        {!customerData.useParentPdfSettings &&
                            <div className="col-span-12 mt-8 p-4 rounded-sm border border-surface-6 relative"><label
                                className="text-text-1 absolute bg-surface-2 -top-3.5 pr-1">PDF Options</label>

                                {customerData?.customerType && customerData.customerType.value === "WHOLESALE" &&
                                    <>
                                        <SelectField
                                            placeholder='Select PDF Logo Option...'
                                            value={pdfSettingData?.pdfHeaderOption || null}
                                            options={createOptionListForSelectTag(enums?.["PdfHeaderOption"], "displayName", "constant")}
                                            onChange={(selectedOption) => {
                                                handlePdfSettingOnChange({
                                                    name: "pdfHeaderOption",
                                                    value: selectedOption
                                                })
                                            }}
                                            required={true}
                                            label={"PDF Logo Option"}
                                        />


                                        {pdfSettingData?.pdfHeaderOption && pdfSettingData.pdfHeaderOption.value === "TITLE" &&
                                            <InputField
                                                name={"pdfHeaderTitle"}
                                                label="PDF Logo Title"
                                                value={pdfSettingData?.pdfHeaderTitle || ""}
                                                type="text"
                                                placeholder="Enter PDF Logo Title..."
                                                onChange={handlePdfSettingOnChange}
                                            />
                                        }

                                        {pdfSettingData?.pdfHeaderOption && pdfSettingData.pdfHeaderOption.value === "LOGO" &&
                                            <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 mt-3`}>
                                                <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                                                    <h2 className="text-text-1">PDF Logo</h2>
                                                    {pdfSettingData?.pdfLogoPath &&
                                                        <>
                                                            <p className="label-color bold mb-0 ml-2 mr-2 text-highlight-3 cursor-pointer"
                                                               title={pdfSettingData?.pdfLogoPath}
                                                               onClick={() => {
                                                                   handleViewDownloadFile(pdfImageByNameIdQuery?.data?.id).then()
                                                               }}> (Current Logo)
                                                            </p>

                                                            <ModalButton
                                                                triggerButton={
                                                                    <FaTrash
                                                                        className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                                                        title={"Remove Current Logo"}/>
                                                                }
                                                                onOpen={() => {
                                                                }}
                                                                modal={
                                                                    <ConfirmationModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        title={"Remove Current Logo"}
                                                                        bodyMessage={"Are you sure you want to remove current logo ?"}
                                                                        onSubmit={() => {
                                                                            setPdfSettingData((prevData: any) => ({
                                                                                ...prevData,
                                                                                ["pdfLogoPath"]: null
                                                                            }));
                                                                            setRender(new Date().getMilliseconds());
                                                                        }}
                                                                        isLoading={false}
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    }
                                                </div>
                                                <div className="col-span-9">
                                                    <input
                                                        className={"w-full bg-surface-2 rounded-md border border-surface-4 text-text-1 form_control p-2"}
                                                        ref={fileInputRef}
                                                        onChange={selectedFile}
                                                        name={"file"}
                                                        type="file"
                                                        accept=".jpg,.jpeg,.png,.svg,.webp,image/*"
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {
                                            pdfSettingData.showRemitName && <InputField
                                                name={"remitName"}
                                                label="Remit Name"
                                                onChange={handlePdfSettingOnChange}
                                                value={pdfSettingData?.remitName || ""}
                                                type="text"
                                                placeholder="Enter Remit Name..."
                                            />
                                        }

                                        {pdfSettingData.showRemitEmail && <InputField
                                            name={"remitEmail"}
                                            label="Remit Email"
                                            onChange={handlePdfSettingOnChange}
                                            value={pdfSettingData?.remitEmail || ""}
                                            type="text"
                                            placeholder="Enter Remit Email..."
                                        />}

                                        {pdfSettingData.showRemitPhone && <InputField
                                            name={"remitPhone"}
                                            label="Remit Phone"
                                            onChange={handlePdfSettingOnChange}
                                            value={pdfSettingData?.remitPhone || ""}
                                            type="text"
                                            placeholder="Enter Remit Phone..."
                                        />}

                                        {pdfSettingData.showRemitAddress && <InputField
                                            name={"remitAddress"}
                                            label="Remit Address"
                                            onChange={handlePdfSettingOnChange}
                                            value={pdfSettingData?.remitAddress || ""}
                                            type="text"
                                            placeholder="Enter Remit Address..."
                                        />}
                                    </>
                                }

                                <InputField
                                    name={"showRemitName"}
                                    label="Show Remit Name"
                                    onClick={handlePdfSettingToggle("showRemitName")}
                                    checked={pdfSettingData?.showRemitName || ""}
                                    type="toggle"
                                />

                                <InputField
                                    name={"showRemitEmail"}
                                    label="Show Remit Email"
                                    onClick={handlePdfSettingToggle("showRemitEmail")}
                                    checked={pdfSettingData?.showRemitEmail || ""}
                                    type="toggle"
                                />

                                <InputField
                                    name={"showRemitPhone"}
                                    label="Show Remit Phone"
                                    onClick={handlePdfSettingToggle("showRemitPhone")}
                                    checked={pdfSettingData?.showRemitPhone || ""}
                                    type="toggle"
                                />

                                <InputField
                                    name={"showRemitAddress"}
                                    label="Show Remit Address"
                                    onClick={handlePdfSettingToggle("showRemitAddress")}
                                    checked={pdfSettingData?.showRemitAddress || ""}
                                    type="toggle"
                                />


                                <InputField
                                    name={"showDisclaimerSection"}
                                    label="Show Disclaimer Section"
                                    onClick={handlePdfSettingToggle("showDisclaimerSection")}
                                    checked={pdfSettingData?.showDisclaimerSection || ""}
                                    type="toggle"
                                />

                            </div>
                        }

                    </div>

                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    btnText: "Cancel",
                                    onClick: () => navigate(AppRoutes.Customers.path),
                                    type: "close",
                                },
                                buttonType: "button"
                            },
                            {
                                buttonProps: {
                                    btnText: "Submit",
                                    type: "submit",
                                    isLoading: (updateCustomerApiResponse.isLoading || updatePdfSettingsApiResponse.isLoading),
                                },
                                buttonType: "button",
                            }
                        ]}
                        align="start"
                        gap={2}
                    />

                </div>
            }/>

            <Tabs tabs={bottomTabs} className="bootstrap-tabs-component mt-3"/>

        </>
    )
}
export default SettingTab;