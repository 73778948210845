import React, {FC, FormEvent, useState} from "react"
import {FaLock, FaTrash} from "react-icons/fa6"
import Breadcrumb from "../../components/common/Breadcrumbs";
import {useFindUserProfileInfoQuery} from "./profileApi";
import InputField from "../../components/common/form/InputField";
import ModalButton from "../../components/common/form/ModalButton";
import IconButton from "../../components/common/form/IconButton";
import {hasPermission, jwtDecode} from "../../helpers/utils/AccessControlUtils";
import ResetPassword from "../../components/common/resetPassword/ResetPassword";
import {formatValueAsDate} from "../../helpers/utils/DateUtils";
import ButtonTray from "../../components/common/form/ButtonTray";
import {IoMdAdd} from "react-icons/io";
import CreateMFADeviceModal from "./modal/CreateMFADeviceModal";
import {
    useDeleteMfaDeviceByIdMutation,
    useGetUserMfaDeviceQuery,
    usePreferMfaDeviceByIdMutation
} from "./mfaDeviceApi";
import {skipToken} from "@reduxjs/toolkit/query";
import ConfirmationModal from "../../components/layout/modal/ConfirmationModal";
import VerifyMFADeviceModal from "./modal/VerifyMFADeviceModal";

const Profile: FC = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Profile", url: "/profile"},

    ])

    const {data: userProfileInfo} = useFindUserProfileInfoQuery("");
    const {data: mfaDevices} = useGetUserMfaDeviceQuery("");
    const [selectedId, setSelectedId] = useState<any>("");
    const [deleteMfaDeviceApi, {isLoading: deleteMfaDeviceApiResponse}] = useDeleteMfaDeviceByIdMutation();
    const [preferMfaDeviceApi, {isLoading: preferMfaDeviceApiResponse}] = usePreferMfaDeviceByIdMutation();

    const handleDeleteMfaDeviceSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedId) {
            return deleteMfaDeviceApi(selectedId);
        }
    }

    const handlePreferMfaDeviceSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedId) {
            return preferMfaDeviceApi(selectedId);
        }
    }

    function getModalBody(type: string) {
        if (type === "SMS") {
            return "A verification code has been sent to your mobile device via SMS. Please enter the six-digit code received in the text message to verify your device.";
        } else if (type === "EMAIL") {
            return "A verification code has been sent to your email address. Please enter the six-digit code received in the email to verify your device.";
        } else {
            return "A verification code has been sent to your authenticator app. Please enter the six-digit code generated by the app to verify your device."
        }
    }

    return (
        <>
            <div className="p-5">
                <div className="justify-between sm:block md:flex lg:flex">
                    <Breadcrumb crumbs={crumbs}/>

                    {hasPermission(["SPWRE-V"]) &&
                        <ModalButton
                            triggerButton={
                                <IconButton icon={<FaLock/>} label={"Reset Password"} title={"Reset Password"}/>
                            }
                            modal={
                                <ResetPassword show={true} setShow={() => {
                                }} page={"Profile"}/>
                            }
                        />}

                </div>

                <div className=" flex justify-center mt-3">
                    <div className=" bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Profile
                            Info</h3>
                        <form>
                            <div className=" grid-cols-12 mt-6 sm:block md:grid lg:grid">

                                <InputField
                                    label="Username"
                                    value={userProfileInfo?.username || ""}
                                    type="text"
                                    placeholder="Enter Username..."
                                    disabled={true}
                                    className={"sm:mt-3 md:mt-0 lg:mt-0"}
                                />

                                <InputField
                                    label="Email"
                                    value={userProfileInfo?.email || ""}
                                    type="email"
                                    placeholder="Enter Email..."
                                    disabled={true}
                                />
                                <InputField
                                    label="Customer"
                                    value={userProfileInfo?.customer?.customerName || ""}
                                    placeholder="Customer..."
                                    type="text"
                                    disabled={true}
                                />
                                <InputField
                                    label="Branch"
                                    value={userProfileInfo?.branch?.displayLabel || userProfileInfo?.branch?.name || ""}
                                    placeholder="Branch..."
                                    type="text"
                                    disabled={true}
                                />
                            </div>

                        </form>

                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Security</h3>
                        <InputField
                            label="Last Password Change"
                            value={formatValueAsDate(userProfileInfo?.lastPasswordChangeDate) || ""}
                            placeholder="Last Password Change..."
                            type="text"
                            disabled={true}
                        />
                        {hasPermission(["MFA-R"]) &&
                            <>
                                <div className="col-span-12 grid grid-cols-12 gap-3">
                                    <div className="col-span-3 sm:mt-2 lg:mt-0 mt-2 flex items-center">
                                        <div className="flex items-center"><span
                                            className="text-text-1">MFA Devices ({mfaDevices?.length || 0})</span></div>
                                    </div>
                                    <div className="relative col-span-9 mt-3 flex justify-end">
                                        <ButtonTray
                                            buttons={[
                                                {
                                                    buttonProps: {
                                                        modal: <CreateMFADeviceModal
                                                            show={true}
                                                            setShow={() => {
                                                            }}
                                                        />,
                                                        onOpen: () => {
                                                        },
                                                        triggerButton: <IconButton icon={<IoMdAdd/>}
                                                                                   hasBackground={true}
                                                                                   title={"Add MFA Device"}
                                                                                   className={"h-7"}
                                                        />,
                                                        isVisible: hasPermission(["MFA-C"]) && userProfileInfo?.allowMfa
                                                    },
                                                    buttonType: "modal",
                                                },
                                            ]}/>
                                    </div>
                                </div>

                                {mfaDevices?.map((el: any) => (
                                    <div className="col-span-12 grid grid-cols-12 gap-3">
                                        <div className="col-span-3 sm:mt-2 lg:mt-0 mt-2 flex items-center">
                                        </div>
                                        <div className="relative col-span-9 mt-3">
                                            <div className={"border border-surface-6 p-3 rounded-md bg-surface-2"}>
                                                <div
                                                    className={"flex items-center justify-between border-b border-surface-3"}>
                                                    <h3 className="text-xl font-semibold text-text-1 pb-3">{el.type}</h3>

                                                    {hasPermission(["MFA-D"]) && <ModalButton
                                                        triggerButton={
                                                            <FaTrash
                                                                className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                                                title={"Delete Device"}/>
                                                        }
                                                        onOpen={() => {
                                                            setSelectedId(el.id)
                                                        }}
                                                        modal={
                                                            <ConfirmationModal
                                                                show={true}
                                                                setShow={() => {
                                                                }}
                                                                title={"Delete Device"}
                                                                bodyMessage={"Are you sure you want to delete this device? If this is your only device, it will result in only being able to access your profile."}
                                                                onSubmit={handleDeleteMfaDeviceSubmit}
                                                                isLoading={deleteMfaDeviceApiResponse}
                                                            />
                                                        }
                                                    />}

                                                </div>

                                                <div className="col-span-12 grid grid-cols-12 gap-3 mt-4">
                                                    <div className="col-span-3 sm:mt-2 lg:mt-0 mt-2 flex items-center">
                                                        <div className="flex items-center"><span
                                                            className="text-text-1">Verified</span></div>
                                                    </div>
                                                    <div className="relative col-span-9">

                                                        <ModalButton
                                                            triggerButton={
                                                                <i title={el.verified ? "Verified" : (el.type !== "SMS" ? "Click to verify device" : "")}
                                                                   className={`fa ${el.verified ? "fa-check-circle text-highlight-7" : "fa-times-circle text-error-2"} ${!el.verified && el.type !== "SMS" ? "cursor-pointer" : ""}`}
                                                                ></i>
                                                            }
                                                            onOpen={() => {
                                                                setSelectedId(el.id)
                                                            }}
                                                            modal={
                                                                !el.verified && el.type !== "SMS" ?
                                                                    <VerifyMFADeviceModal
                                                                        show={true}
                                                                        setShow={() => {
                                                                        }}
                                                                        bodyMessage={getModalBody(el.type)}
                                                                        id={el.id}
                                                                        type={el.type}
                                                                    /> : <></>
                                                            }
                                                        />

                                                        {el.verified === false &&
                                                            <span
                                                                className={"ml-2 text-text-1"}>{el.type !== "SMS" ? "Click to verify device" : "SMS feature is not available yet"}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-span-12 grid grid-cols-12 gap-3 mt-4 mb-3">
                                                    <div className="col-span-3 sm:mt-2 lg:mt-0 mt-2 flex items-center">
                                                        <div className="flex items-center"><span
                                                            className="text-text-1">Preferred</span></div>
                                                    </div>
                                                    <div className="relative col-span-9">
                                                        <ModalButton
                                                            triggerButton={
                                                                <i title={el.preferred ? "Preferred" : "Click to make preferred device"}
                                                                   className={el.preferred ? `fa fa-check-circle text-highlight-7` : `fa fa-times-circle text-error-2 cursor-pointer`}></i>
                                                            }
                                                            onOpen={() => {
                                                                setSelectedId(el.id)
                                                            }}
                                                            modal={
                                                                !el.preferred ? <ConfirmationModal
                                                                    show={true}
                                                                    setShow={() => {
                                                                    }}
                                                                    title={"Change Preferred Device"}
                                                                    bodyMessage={"Are you sure you want to change your preference to this device? This will remove other preferences."}
                                                                    onSubmit={handlePreferMfaDeviceSubmit}
                                                                    isLoading={preferMfaDeviceApiResponse}
                                                                /> : <></>
                                                            }
                                                        />

                                                    </div>
                                                </div>

                                                {<InputField
                                                    name="credential"
                                                    label="Credential"
                                                    value={el?.credential || 'N/A'}
                                                    type="text"
                                                    placeholder={"Enter Credential..."}
                                                    disabled={true}
                                                />}


                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>}

                    </div>
                </div>

            </div>
        </>
    )
}
export default Profile